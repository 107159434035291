import React, { Component } from "react";
import { Table, Row, Col, Card, CardBody, Container, Button, Form, FormGroup, Label, Input, FormText,
Pagination, PaginationItem, PaginationLink, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput } from "reactstrap";
import ReactDOM from 'react-dom';
import User from '../../apis/User';
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";
import {DateFormat,DateTimeFormat,Loading,SuccessPopup, SwalClose, ErrorPopup} from "../../helpers/globalfunctions";
import CommonApi from '../../apis/CommonApi';
import Swal from 'sweetalert2';
import Select from "react-select";
import Compressor from 'compressorjs';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class Beneficiary extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Beneficiary", link : "#" },
                { title : "List", link : "#" },
            ],
            listBeneficiary:[],
            pageCount: 1,
            currentPage: 1,
            per_page:100,
            search:'',
            isWaiting:false,
            modalIsOpen: false,
            editModalIsOpen: false,
            selectedMulti: null,
            selectedMultiAreaCluster: null,
            selectedMultiArea: null,
            area_name: '',
            values:[],
            area_id:'',
            area_type:'',
            area_type_id:'',
            area_cluster_id:'',
            importModalIsOpen:false,
            compressedFile:[],
            compressedResult:'',
            editFormData:[],
            update_id:'',
            deleteModalIsOpen:false,
            delete_id:'',            
        };

        this.handlePageClick = this.handlePageClick.bind(this);
        this.rowPerPageChange = this.rowPerPageChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.statusChange=this.statusChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMulti = this.handleMulti.bind(this);
        this.areaList= this.areaList.bind(this);
        this.openModal=this.openModal.bind(this);
        this.GetAreaTypeList= this.GetAreaTypeList.bind(this);
        this.areaClusterList=this.areaClusterList.bind(this);
        this.handleImportBeneficiary = this.handleImportBeneficiary.bind(this);
        this.handleCompressedUpload= this.handleCompressedUpload.bind(this);
        this.openEditModal=this.openEditModal.bind(this);
        this.handleEdit=this.handleEdit.bind(this);
        this.onChange=this.onChange.bind(this);
        this.handleMultiAreaCluster=this.handleMultiAreaCluster.bind(this);
        this.deleteConfirmModal=this.deleteConfirmModal.bind(this);
        this.handleDeleteBeneficiary=this.handleDeleteBeneficiary.bind(this);
    }

    componentDidMount() {
        this._isMounted = true
        this.getBeneficiaryData()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //Get Use data
    getBeneficiaryData() {
        CommonApi.beneficiaryList(this.state.currentPage,this.state.per_page,this.state.search).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({listBeneficiary: response.data.data.data});
                    this.setState({pageCount: response.data.data.last_page});
                    this.setState({currentPage: response.data.data.current_page});
                    this.setState({per_page: response.data.data.per_page});
                    
                }
            }
        });
    }

    // Get Area type list
    GetAreaTypeList(){
        CommonApi.GetAreaTypeList().then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({area_type_id: [{label: "Select",options:response.data.data}]});
                }                    
            }
        });
    }

    // Get area cluster list 
    areaClusterList(data){
        CommonApi.getAreaClusterList(data).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({area_cluster_id: [{label: "Select",options:response.data.data}]});
                }                    
            }
        });
    }

    // Get area list
    areaList(data){
        CommonApi.getAreaList(data).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({area_id: [{label: "Select",options:response.data.data}]});
                }                    
            }
        });
    }

     // row per page    
    async rowPerPageChange(event) {        
        await this.setState({per_page: event.target.value});        
        this.getBeneficiaryData()
    }

    // page change event
    async handlePageClick(data) {
        const page = data.selected >= 0 ? data.selected + 1 : 0;
        await Promise.resolve(this.setState(() => ({ currentPage: page })));        
        this.getBeneficiaryData()
    }

    // on change search
    async handleSearch(searchText) {
        await this.setState({ search: searchText.target.value });
        await Promise.resolve(this.setState(() => ({ currentPage: 1 })));
        this.getBeneficiaryData()
    }
    

    /* change active status */
    async statusChange(id){
        Loading()
        this.setState({ isWaiting: true });
        CommonApi.ChangeUserStatus(id).then(response => {
            SwalClose()
            if (response) {                
                this.getBeneficiaryData();
                this.setState({ isWaiting: false });
            }
        });
    }    

    /* add beneficiary model */
    openModal(){
        this.setState({ modalIsOpen: true });
        this.GetAreaTypeList();
        this.areaClusterList();
        this.areaList();
    }    
    closeModal = () => this.setState({ modalIsOpen: false });

    /* Edit beneficiary model */
    async openEditModal(id){
        Loading()
        this.setState({ editModalIsOpen: true });
        this.setState({update_id:id});
        this.GetAreaTypeList();
        this.areaList();

        CommonApi.beneficiaryEditData(id).then(response => {
            if (response) {
                this.setState({editFormData: response.data.data.result});
                this.setState({selectedMulti:[{value: response.data.data.result.area_type_id, label: response.data.data.result.area_type_name}]})
                this.setState({selectedMultiAreaCluster:[{value: response.data.data.result.area_cluster_id, label: response.data.data.result.area_cluster_name}]})
                this.setState({selectedMultiArea:[{value: response.data.data.result.area_id, label: response.data.data.result.area_name }]})

                SwalClose()
                
            }
        });
    }    
    closeEditModal = () => this.setState({ editModalIsOpen: false });


    /* import beneficiary model */
    openImportModal = () => this.setState({ importModalIsOpen: true });
    closeImportModal = () => this.setState({ importModalIsOpen: false });

    // Add Beneficiary API
    handleSubmit(event) {
        event.preventDefault();
        Loading()
        const data = new FormData(event.target);
        var files = this.state.compressedFile;
        files.map((file, index) => {
            data.append(`file[${index}]`, file);
        });

        CommonApi.AddBeneficiary(data).then(response => {
            SwalClose()
            
            if (response.data) {
                this.setState({modalIsOpen:false});
                SuccessPopup(response.data.message)
                this.getBeneficiaryData();
            }            
        }).catch((error) => {            
            SwalClose()
            if (error.response) {
                ErrorPopup(error.response)
            }

        });      
    }

    async onChange(e){
        //console.log(e.target.name);
        //console.log(e.target.value);
        
        let ChangeName=e.target.name;
        const ChangeValue=e.target.value;
        await this.setState(prevState => ({
            editFormData: {                   // object that we want to update
                ...prevState.editFormData,    // keep all other key-value pairs
                [ChangeName]: ChangeValue       // update the value of specific key
            }
        }));

        console.log(this.state.editFormData);
    }

    /* Edit/Update beneficiary */
    handleEdit(event) {
        event.preventDefault();
        Loading()
        const data = new FormData(event.target);
        data.append('update_id',this.state.update_id);
        var files = this.state.compressedFile;
        files.map((file, index) => {
            data.append(`file[${index}]`, file);
        });

        CommonApi.updateBeneficiary(data).then(response => {
            SwalClose()
            
            if (response.data) {
                this.setState({editModalIsOpen:false});
                SuccessPopup(response.data.message)
                this.getBeneficiaryData();
            }
        }).catch((error) => {
            SwalClose()
            if (error.response) {
                ErrorPopup(error.response)
            }

        });      
    }    

    /* select area type */
    handleMulti = selectedMulti => {
        this.setState({ selectedMulti });        
        this.setState({selectedMultiAreaCluster:[]})
        this.areaClusterList(selectedMulti.value);
        this.setState({selectedMultiArea:[]})
    };

    /* Select area cluster */
    handleMultiAreaCluster = selectedMultiAreaCluster => {
        this.setState({ selectedMultiAreaCluster });
        this.setState({selectedMultiArea:[]})
        this.areaList(selectedMultiAreaCluster.value);
    };

    /* Select area */
    handleMultiArea = selectedMultiArea => {
        this.setState({ selectedMultiArea });
    };

    /* Import beneficiary file */
    handleImportBeneficiary(event){
        event.preventDefault();
        Loading()
        const data = new FormData(event.target);
        CommonApi.importBeneficiary(data).then(response => {
            SwalClose()
            
            if (response.data) {
                this.setState({importModalIsOpen:false});
                SuccessPopup(response.data.message)
                this.getBeneficiaryData();
            }
        }).catch((error) => {
            SwalClose()
            if (error.response) {
                ErrorPopup(error.response)
            }

        });
    }

    /* compress image */
    handleCompressedUpload(e){
        //console.log(e.target.files[0]);
        //var el= document.getElementById('file')addEventListener('change', (e) => {
        //document.getElementById('file').onChangeValue('change', (e) => {
        Loading()
          const file = e.target.files[0];
          var self = this;

          if (!file) {
            SwalClose()
            return;
          }
          //const assignState=this.setState('compressedFile');
          new Compressor(file, {
            quality: 0.6,

            // The compression process is asynchronous,
            // which means you have to access the `result` in the `success` hook function.
            success(result) {

                self.setState({compressedFile:  self.state.compressedFile.concat(result)});
                            
                console.log("result");
                console.log(result);
                SwalClose()              
            },
            error(err) {
                SwalClose()
                console.log(err.message);
            },
          });        
    }

    /* Delete Confirm Modal */
    async deleteConfirmModal(id){
        await this.setState({deleteModalIsOpen:true});
        await this.setState({delete_id:id});
    }

    /* Delete function */
    handleDeleteBeneficiary(event){
        event.preventDefault();
        Loading()        

        CommonApi.deleteBeneficiary(this.state.delete_id).then(response => {
            SwalClose()
            
            if (response.data.status==1) {
                this.setState({deleteModalIsOpen:false});
                SuccessPopup(response.data.message)
                this.getBeneficiaryData();
            }
        }).catch((error) => {
            SwalClose()
            if (error.response) {
                ErrorPopup(error.response)
            }

        });
    }

    render() {

        const { selectedMulti } = this.state;
        const { selectedMultiArea } = this.state;
        const { selectedMultiAreaCluster } = this.state;

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Beneficiary List" breadcrumbItems={this.state.breadcrumbItems} />
                        

                        <Row>
                            <Col lg={12} className="mb-2">
                                <div>
                                  <Button color="primary" onClick={this.openModal}>Add New Beneficiary</Button>
                                  {(localStorage.getItem("user.user_scopes")=='admin')?(
                                    <Button className="ml-2" color="success" onClick={this.openImportModal}>Import Beneficiary</Button>
                                    ):null
                                  }

                                  {/* Add beneficiary model */}
                                  <Modal isOpen={this.state.modalIsOpen}>
                                    <ModalHeader>Add Beneficiary</ModalHeader>
                                    <Form onSubmit={this.handleSubmit}>
                                    <ModalBody>

                                            <FormGroup className="select2-container">
                                                <Label className="control-label">Select City/Village* (Area Type)</Label>
                                                <Select name="area_type"
                                                    value={selectedMulti}
                                                    isMulti={false}
                                                    onChange={this.handleMulti}
                                                    options={this.state.area_type_id}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </FormGroup>

                                            <FormGroup className="select2-container">
                                                <Label className="control-label">Select Area Cluster*</Label>
                                                <Select name="area_cluster"
                                                    value={selectedMultiAreaCluster}
                                                    isMulti={false}
                                                    onChange={this.handleMultiAreaCluster}
                                                    options={this.state.area_cluster_id}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </FormGroup>

                                            <FormGroup className="select2-container">
                                                <Label className="control-label">Select Area/Village* (Area)</Label>
                                                <Select name="area_name"
                                                    value={selectedMultiArea}
                                                    isMulti={false}
                                                    onChange={this.handleMultiArea}
                                                    options={this.state.area_id}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="beneficiary_name">Beneficiary Name*</Label>
                                                <Input type="text" name="beneficiary_name" id="beneficiary_name" placeholder="Beneficiary Name" />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="street_area">Area/Street</Label>
                                                <Input type="text" name="street_area" id="street_area" placeholder="Area or Street name" />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="mobile_no">Mobile Number*</Label>
                                                <Input type="text" name="mobile_no" id="mobile_no" placeholder="Enter Mobile Number" />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="beneficiary_photo">Beneficiary Photo</Label>                                                
                                                <input
                                                    accept="image/*,capture=camera"
                                                    capture="”camera"
                                                    type="file"
                                                    name="beneficiary_photo[]" 
                                                    id="beneficiary_photo"
                                                    onChange={(event) => this.handleCompressedUpload(event)}
                                                  />
                                            </FormGroup>
                                            
                                        
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="secondary" onClick={this.closeModal}>Cancel</Button>{' '}
                                        <Button type="submit" color="primary">Save</Button>                           
                                    </ModalFooter>
                                    </Form>
                                  </Modal>


                              {/* Edit beneficiary model */}
                                  <Modal isOpen={this.state.editModalIsOpen}>
                                    <ModalHeader>Edit Beneficiary</ModalHeader>
                                    <Form onSubmit={this.handleEdit}>
                                    <ModalBody>

                                            <FormGroup className="select2-container">
                                                <Label className="control-label">Select City/Village* (Area Type)</Label>
                                                <Select name="area_type"
                                                    defaultValue={this.state.area_type_id[1]}
                                                    value={selectedMulti}
                                                    isMulti={false}
                                                    onChange={this.handleMulti}
                                                    options={this.state.area_type_id}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </FormGroup>

                                            <FormGroup className="select2-container">
                                                <Label className="control-label">Select Area Cluster*</Label>
                                                <Select name="area_cluster"                                                    
                                                    value={selectedMultiAreaCluster}
                                                    isMulti={false}
                                                    onChange={this.handleMultiAreaCluster}
                                                    options={this.state.area_cluster_id}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </FormGroup>

                                            <FormGroup className="select2-container">
                                                <Label className="control-label">Select Area/Village* (Area)</Label>
                                                <Select name="area_name"
                                                    value={selectedMultiArea}
                                                    isMulti={false}
                                                    onChange={this.handleMultiArea}
                                                    options={this.state.area_id}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="beneficiary_name">Beneficiary Name*</Label>
                                                <Input type="text" name="beneficiary_name" id="beneficiary_name" placeholder="Beneficiary Name" value={this.state.editFormData.beneficiary_name || "" } onChange={this.onChange} />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="street_area">Area/Street</Label>
                                                <Input type="text" name="street_area" id="street_area" placeholder="Area or Street name" value={this.state.editFormData.street_area || "" } onChange={this.onChange} />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="mobile_no">Mobile Number*</Label>
                                                <Input type="text" name="mobile_no" id="mobile_no" placeholder="Enter Mobile Number" value={this.state.editFormData.mobile_no || "" } onChange={this.onChange} />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="beneficiary_photo">Beneficiary Photo</Label>                                                
                                                <input
                                                    accept="image/*,capture=camera"
                                                    capture="”camera"
                                                    type="file"
                                                    name="beneficiary_photo[]" 
                                                    id="beneficiary_photo"
                                                    onChange={(event) => this.handleCompressedUpload(event)}
                                                  />
                                            </FormGroup>

                                            <div>
                                                <img src={(this.state.editFormData.attachments?.length > 0 )?this.state.editFormData.attachments[0].path:''} width="100px" height="100px" alt="Photo" />
                                            </div>
                                            
                                        
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="secondary" onClick={this.closeEditModal}>Cancel</Button>{' '}
                                        <Button type="submit" color="primary">Update</Button>                           
                                    </ModalFooter>
                                    </Form>
                                  </Modal>


                                  {/* Import beneficiary model */}
                                  <Modal isOpen={this.state.importModalIsOpen}>
                                    <ModalHeader>Import Beneficiary</ModalHeader>
                                    <Form onSubmit={this.handleImportBeneficiary}>
                                    <ModalBody>                                           

                                            <FormGroup>
                                                <Label for="beneficiary_file">Select Beneficiary Excel file*</Label>
                                                <Input type="file" name="beneficiary_file" id="beneficiary_file" placeholder="Select file" />
                                            </FormGroup>                                            
                                        
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="secondary" onClick={this.closeImportModal}>Cancel</Button>{' '}
                                        <Button type="submit" color="primary">Import</Button>                           
                                    </ModalFooter>
                                    </Form>
                                  </Modal>


                                {/* deleteModal */ }
                                <Modal isOpen={this.state.deleteModalIsOpen}>
                                    <ModalHeader>Delete Beneficiary? </ModalHeader>
                                    <Form onSubmit={this.handleDeleteBeneficiary}>
                                    <ModalBody>                                           

                                        <p>Are you sure you want to delete Beneficiary?</p>
                                        
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="secondary" onClick={()=>this.setState({deleteModalIsOpen:false})}>Cancel</Button>{' '}
                                        <Button type="submit" color="primary">Delete</Button>                           
                                    </ModalFooter>
                                    </Form>
                                </Modal>

                                </div>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>                                       

                                        <Row>
                                            <Col lg={2}>
                                                <Row>
                                                    <Col lg={3}>
                                                        <FormGroup className="mt-2">                                                    
                                                            <Label for="selectRows">Show</Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <FormGroup>                                                    
                                                            <Input type="select" name="selectRows" onChange={this.rowPerPageChange} id="selectRows" value={this.state.per_page}
                                                             >
                                                              <option value='50'>50</option>
                                                              <option value='100'>100</option>
                                                              <option value='200'>200</option>
                                                              <option value='500'>500</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>                                                 
                                            </Col>
                                            <Col lg={7}>
                                                {/*<Button>Print</Button>*/}
                                            </Col>
                                            <Col lg={3}>
                                                 <Row>
                                                    <Col lg={3}>
                                                        <FormGroup className="mt-2">                                    
                                                            <Label for="search">search</Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={9}>
                                                        <Form>
                                                          <FormGroup>                                                            
                                                            <Input type="text" onKeyUp={this.handleSearch} name="search" id="search" placeholder="" />
                                                          </FormGroup>
                                                        </Form>
                                                    </Col>
                                                 </Row>                                                
                                            </Col>

                                        </Row>


        
                                        <div className="table-responsive">
                                            <Table className="mb-0" striped bordered hover>
                                                <thead className="bg-primary text-white">
                                                    <tr>                                                        
                                                        <th>Name</th>
                                                        <th>Photo</th>
                                                        <th>Area/Street</th>
                                                        <th>Area/Village</th>
                                                        <th>Area Cluster</th>
                                                        <th>City/Village</th>
                                                        <th>Mobile</th>
                                                        <th>QR Code</th>               
                                                        <th>Dist Token No</th>
                                                        <th>Card Validity</th>
                                                        <th>Card Generated</th>
                                                        <th>Created By</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.listBeneficiary ?(
                                                            this.state.listBeneficiary.map((option,index)=>{
                                                                return <tr key={index}>                                                                          
                                                                          <td>{option.beneficiary_name}</td>
                                                                          <td>{option.attachments?(
                                                                                    option.attachments.map((attValue, attIndex)=>{
                                                                                        return <a key={attIndex} href={(attValue.attachment_name=='beneficiary_photo')?attValue.path:''} target="_blank">{(attValue.attachment_name=='beneficiary_photo')?<img src={attValue.path} width="50px" height="50px" />:''}</a>
                                                                                    })
                                                                                    ):null
                                                                            }</td>
                                                                          <td>{option.street_area}</td>
                                                                          <td>{option.area_name}</td>
                                                                          <td>{option.area_cluster_name}</td>
                                                                          <td>{option.area_type_name}</td>              
                                                                          <td>{option.mobile_no}</td>
                                                                          <td>{option.zpuid}</td>
                                                                          <td>{option.distribution_token_no}</td>
                                                                          <td>{option.card_validity}</td>
                                                                          <td>{option.card_generated}</td>
                                                                          <td>{option.created_by}</td>
                                                                          <td>{option.status}</td>                                                                          
                                                                          <td>
                                                                            <Button color="primary" className="btn-sm mr-1" onClick={()=>this.openEditModal(option.id)}><i className="ri-edit-2-fill"></i></Button>
                                                                            <Button color="danger" className="btn-sm" onClick={()=>this.deleteConfirmModal(option.id)}><i className="ri-delete-bin-2-line"></i></Button>
                                                                          </td>
                                                                       </tr>
                                                            })
                                                        ): null
                                                    }                                                   
                                                   
                                                    
                                                </tbody>
                                            </Table>
                                        </div>

                                    {/* paginnation */}
                                    <ReactPaginate
                                      previousLabel="&#x276E;"
                                      nextLabel="&#x276F;"
                                      breakLabel={'...'}
                                      breakClassName={'break-me'}
                                      pageCount={this.state.pageCount}
                                      initialPage={this.state.currentPage - 1}
                                      forcePage={this.state.currentPage - 1}
                                      marginPagesDisplayed={2}
                                      pageRangeDisplayed={5}
                                      onPageChange={this.handlePageClick}
                                      containerClassName={'pagination mt-4 float-right'}
                                      activeClassName={'active'}
                                      disableInitialCallback={true}
                                    /> 
        
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Beneficiary;