import React, { Component } from "react";
import { Table, Row, Col, Card, CardBody, Container, Button, Form, FormGroup, Label, Input, FormText,
Pagination, PaginationItem, PaginationLink, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput } from "reactstrap";
import ReactDOM from 'react-dom';
import User from '../../apis/User';
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";
import {DateFormat,DateTimeFormat,Loading,SuccessPopup, SwalClose, ErrorPopup,ErrorMessage,Checkbox} from "../../helpers/globalfunctions";
import CommonApi from '../../apis/CommonApi';
import Swal from 'sweetalert2';
import Select from "react-select";
import Compressor from 'compressorjs';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class RouteGenerate extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Route", link : "#" },
                { title : "Generate", link : "#" },
            ],
            listArea:[],
            pageCount: 1,
            currentPage: 1,
            per_page:100,
            search:'',
            isWaiting:false,            
            selectedMulti: '',
            selectedMultiAreaCluster:'',
            selectedMultiArea: '',
            area_name: '',
            values:[],
            area_id:'',
            area_type:'',
            area_type_id:'',
            cardStatus:[],
            checkedItems: new Map(),
            Checked: false,
            city_village:'',
            area_cluster:'',
            area_village:'',
            routeGenerateModalIsOpen:false,
            card_generated:'No',
            check_all:false,
            isCheckAll:false,
            isCheck:[],
            checkboxes: [],
            vendor:[],
        };

        this.handlePageClick = this.handlePageClick.bind(this);
        this.rowPerPageChange = this.rowPerPageChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);        
        this.handleRouteGenerate = this.handleRouteGenerate.bind(this);
        this.handleMulti = this.handleMulti.bind(this);
        this.handleMultiAreaCluster=this.handleMultiAreaCluster.bind(this);              
        this.GetAreaTypeList= this.GetAreaTypeList.bind(this);
        this.areaClusterList=this.areaClusterList.bind(this);        
        this.checkall=this.checkall.bind(this);
        this.getRouteAreaData=this.getRouteAreaData.bind(this);
        this.searchRecord=this.searchRecord.bind(this);
        this.openRouteGenerateModal=this.openRouteGenerateModal.bind(this);
    }

    componentDidMount() {
        this._isMounted = true        
        this.GetAreaTypeList()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //Get Use data
    getRouteAreaData(){
        Loading()
        CommonApi.getRouteNonGenerated(this.state.currentPage,this.state.per_page,this.state.search,this.state.city_village,this.state.area_cluster).then(response => {
            if (response) {
                SwalClose()
                if(this._isMounted) {                    
                    this.setState({listArea: response.data.data.result});                    
                    const OPTIONS  = response.data.data.result.map(row => row.id);
                    //console.log(OPTIONS);
                    this.setState({checkboxes: OPTIONS.reduce(
                      (options, option) => ({
                        ...options,
                        [option]: false
                      }),
                      {}
                    )})                    
                }                
            }
        }).catch((error) => {
            SwalClose()
            if (error.response) {
                ErrorPopup(error.response)
            }

        });
    }

    // Get Area type list
    GetAreaTypeList(){
        CommonApi.GetAreaTypeList().then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({area_type_id: [{label: "Select",options:response.data.data}]});
                }                    
            }
        });
    }

    // Get area cluster list 
    areaClusterList(data){
        CommonApi.getAreaClusterList(data).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({area_cluster_id: [{label: "Select",options:response.data.data}]});
                }                    
            }
        });
    }

    /* search button event */
    searchRecord(event){        
        this.getRouteAreaData();
    }

    //get vendor list for generate route
    getvendorList(){
        CommonApi.vendorList().then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({vendor: response.data.data});
                }                    
            }
        });
    }

     // row per page    
    async rowPerPageChange(event) {        
        await this.setState({per_page: event.target.value});
    }

    // page change event
    async handlePageClick(data) {
        const page = data.selected >= 0 ? data.selected + 1 : 0;
        await Promise.resolve(this.setState(() => ({ currentPage: page })));        
        this.getRouteAreaData()
    }

    // on change search
    async handleSearch(searchText) {
        await this.setState({ search: searchText.target.value });
        await Promise.resolve(this.setState(() => ({ currentPage: 1 })));
        this.getRouteAreaData()
    }

    /* select area type */
    handleMulti = selectedMulti => {
        this.setState({ selectedMulti });
        this.setState({ city_village: selectedMulti.value });
        this.setState({selectedMultiAreaCluster:[]})
        this.areaClusterList(selectedMulti.value);
    };

    /* Select area cluster */
    handleMultiAreaCluster = selectedMultiAreaCluster => {
        this.setState({ selectedMultiAreaCluster });
        this.setState({ area_cluster: selectedMultiAreaCluster.value });                
    };
    
    // check all
    async checkall(event) {
        const isChecked = event.target.checked;        
        if(isChecked==true){                        
            this.selectAllCheckboxes(true);
        }else{            
            this.selectAllCheckboxes(false);
        }
    }

    /* Route date model */
    openRouteGenerateModal(e){
        //this.getvendorList()
        this.setState({ routeGenerateModalIsOpen: true })
    }
    closeRouteGenerateModal = () => this.setState({ routeGenerateModalIsOpen: false });

    // Route genarate request
    handleRouteGenerate(event) {
        event.preventDefault();
        const beneficiaryCheck=this.state.checkboxes;
        console.log(beneficiaryCheck);
        if(beneficiaryCheck==''){
            console.log('empty');
            ErrorMessage("Please select Area before generate route");

            return false;
        }

        Loading()
        const formData = new FormData(event.target);        

        Object.keys(this.state.checkboxes)
          .filter(checkbox => this.state.checkboxes[checkbox])
          .forEach(checkbox => {            
            formData.append('area_id[]',checkbox);
        });        

        CommonApi.generateRoute(formData).then(response => {
            SwalClose()
            
            if (response.data) {
                this.setState({routeGenerateModalIsOpen:false});                
                SuccessPopup(response.data.message)
                this.getRouteAreaData()                
                this.selectAllCheckboxes(false);
            }
        }).catch((error) => {
            SwalClose()
            if (error.response) {
                ErrorPopup(error.response)
            }

        });      
    }    

    handleCheckboxChange = changeEvent => {        
        const { name } = changeEvent.target;
        const isChecked = event.target.checked;
        console.log(name);
        this.setState(prevState => ({
          checkboxes: {
            ...prevState.checkboxes,
            [name]: !prevState.checkboxes[name]
          }
        }));            
        
    };

    selectAllCheckboxes = isSelected => {
        //console.log('select all');        
        Object.keys(this.state.checkboxes).forEach(checkbox => {
          
          this.setState(prevState => ({
            checkboxes: {
              ...prevState.checkboxes,
              [checkbox]: isSelected
            }
          }));
        });        
    };  

    render() {

        const { selectedMulti } = this.state;
        const { selectedMultiArea } = this.state;
        const { selectedMultiAreaCluster } =this.state;        

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Route Generate" breadcrumbItems={this.state.breadcrumbItems} />
                        
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col lg={3}>
                                                <FormGroup className="select2-container">
                                                    <Label className="control-label">Select City/Village* (Area Type)</Label>
                                                    <Select name="area_type"
                                                        value={selectedMulti}
                                                        isMulti={false}
                                                        onChange={this.handleMulti}
                                                        options={this.state.area_type_id}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg={3}>

                                                <FormGroup className="select2-container">
                                                    <Label className="control-label">Select Area Cluster*</Label>
                                                    <Select name="area_cluster"
                                                        value={selectedMultiAreaCluster}
                                                        isMulti={false}
                                                        onChange={this.handleMultiAreaCluster}
                                                        options={this.state.area_cluster_id}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </FormGroup>
                                            </Col>                                            
                                            
                                            <Col lg={1} sm={6}>
                                                <Button className="mt-4" color="primary" onClick={this.searchRecord}>Search</Button>
                                            </Col>

                                            <Col lg={5}>
                                                <Button className="mt-4" color="success" onClick={this.openRouteGenerateModal}>Generate Route</Button>
                                            </Col>
                                        </Row>

                                        <Row>
                                            {/* Route generate model */}
                                              <Modal isOpen={this.state.routeGenerateModalIsOpen}>
                                                <ModalHeader>Generate Route</ModalHeader>
                                                <Form onSubmit={this.handleRouteGenerate}>
                                                <ModalBody>

                                                        {/*<FormGroup>
                                                            <Label for="vendor">Select Vendor*</Label>
                                                            <Input type="select" name="vendor" id="vendor">
                                                                <option value="">Select</option>
                                                                {
                                                                    this.state.vendor ? (this.state.vendor.map((value,key)=>{
                                                                        return <option key={key} value={value.id}>{value.first_name}</option>
                                                                    })

                                                                    ):null
                                                                }

                                                            </Input>

                                                        </FormGroup> */}

                                                        <FormGroup>
                                                            <Label for="distribution_date">Select Distribution Date*</Label>
                                                            <Input type="date" name="distribution_date" id="distribution_date" placeholder="Select Distribution Date" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="distribution_from_time">Select Distribution From Time*</Label>
                                                            <Input type="time" name="distribution_from_time" id="distribution_from_time" placeholder="Select Distribution from time" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="distribution_to_time">Select Distribution To Time*</Label>
                                                            <Input type="time" name="distribution_to_time" id="distribution_to_time" placeholder="Select Distribution To time" />
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Label for="venue">Venue</Label>
                                                            <Input type="text" name="venue" id="venue" placeholder="Enter Venue Name" />
                                                        </FormGroup>
                                                    
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button color="secondary" onClick={this.closeRouteGenerateModal}>Cancel</Button>{' '}
                                                    <Button type="submit" color="primary">Generate</Button>                           
                                                </ModalFooter>
                                                </Form>
                                              </Modal>
                                        </Row>

        
                                        <div className="table-responsive mt-3">
                                            <Table className="mb-0" striped bordered hover>
                                                <thead className="bg-primary text-white">
                                                    <tr>
                                                        <th>
                                                            
                                                            <FormGroup check>                                                                
                                                                <Input type="checkbox" name="check_all" id="check_all" defaultChecked={this.state.check_all} value=""
                                                                onChange={this.checkall}
                                                                className="" 
                                                                style={{width: "20px",height: "20px"}}
                                                                />
                                                                <Label for="check_all" className="ml-1 pt-2">All</Label>                                                                            
                                                            </FormGroup>

                                                        </th>                                                        
                                                        <th>Area No</th>
                                                        <th>Area Name</th>
                                                        <th>Area Cluster Name</th>
                                                        <th>Status</th>                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.listArea ?(
                                                            this.state.listArea.map((option,index)=>{
                                                                return <tr key={index}>
                                                                            <td>
                                                                                <FormGroup check>
                                                                                    <Checkbox
                                                                                      key={option.id}
                                                                                      type="checkbox"
                                                                                      label={option.id}
                                                                                      id={option.id}                                                                                      
                                                                                      isSelected={this.state.checkboxes[option.id]}
                                                                                      onCheckboxChange={this.handleCheckboxChange}
                                                                                    />
                                                                                </FormGroup>
                                                                            </td>                                                                          
                                                                          <td>{option.id}</td>                                                                          
                                                                          <td>{option.name}</td>                                                                          
                                                                          <td>{option.area_cluster_name}</td>              
                                                                          <td>{option.status}</td>                                                                          
                                                                       </tr>
                                                            })
                                                        ): null
                                                    }                                                   
                                                   
                                                    
                                                </tbody>
                                            </Table>
                                        </div>

                                    {/* paginnation */}
                                    <ReactPaginate
                                      previousLabel="&#x276E;"
                                      nextLabel="&#x276F;"
                                      breakLabel={'...'}
                                      breakClassName={'break-me'}
                                      pageCount={this.state.pageCount}
                                      initialPage={this.state.currentPage - 1}
                                      forcePage={this.state.currentPage - 1}
                                      marginPagesDisplayed={2}
                                      pageRangeDisplayed={5}
                                      onPageChange={this.handlePageClick}
                                      containerClassName={'pagination mt-4 float-right'}
                                      activeClassName={'active'}
                                      disableInitialCallback={true}
                                    /> 
        
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default RouteGenerate;