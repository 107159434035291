import React, { Component } from "react";
import { Table, Row, Col, Card, CardBody, Container, Button, Form, FormGroup, Label, Input, FormText,
Pagination, PaginationItem, PaginationLink, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput } from "reactstrap";
import ReactDOM from 'react-dom';
import User from '../../apis/User';
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";
import {DateFormat,DateTimeFormat,Loading,SuccessPopup, SwalClose, ErrorPopup,ErrorMessage,Checkbox} from "../../helpers/globalfunctions";
import CommonApi from '../../apis/CommonApi';
import Swal from 'sweetalert2';
import Select from "react-select";
import Compressor from 'compressorjs';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class DeliveredKit extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Verifiyer", link : "#" },
                { title : "Delivered Kit List", link : "#" },
            ],
            listKitTrack:[],
            pageCount: 1,
            currentPage: 1,
            per_page:500,
            search:'',
            isWaiting:false,            
            selectedMulti: '',
            selectedMultiAreaCluster:'',
            selectedMultiArea: '',
            area_name: '',
            values:[],
            area_id:'',
            area_type:'',
            area_type_id:'',
            cardStatus:[],
            checkedItems: new Map(),
            Checked: false,
            city_village:'',
            area_cluster:'',
            area_village:'',
            cardGenerateModalIsOpen:false,
            verified_kit:'No',
            check_all:false,
            isCheckAll:false,
            isCheck:[],
            checkboxes: [],
            kit_track_id:'',
        };

        this.handlePageClick = this.handlePageClick.bind(this);
        this.rowPerPageChange = this.rowPerPageChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.statusChange=this.statusChange.bind(this);
        this.handleKitVerification = this.handleKitVerification.bind(this);
        this.handleMulti = this.handleMulti.bind(this);
        this.handleMultiAreaCluster=this.handleMultiAreaCluster.bind(this);
        this.areaList= this.areaList.bind(this);        
        this.GetAreaTypeList= this.GetAreaTypeList.bind(this);
        this.areaClusterList=this.areaClusterList.bind(this);
        this.searchRecord=this.searchRecord.bind(this);
        this.handleInputChange=this.handleInputChange.bind(this);
        this.uncheckall=this.uncheckall.bind(this);
        this.checkall=this.checkall.bind(this);
        this.verifiedBeneficiaryChange=this.verifiedBeneficiaryChange.bind(this);
        this.openVerifyModal=this.openVerifyModal.bind(this);
        
    }

    componentDidMount() {
        this._isMounted = true        
        this.GetAreaTypeList()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //Get Use data
    getKitDistributionData() {
        Loading()
        CommonApi.kitDistributionNonOrVerifiyList(this.state.currentPage,this.state.per_page,this.state.search,this.state.city_village,this.state.area_village,this.state.verified_kit,this.state.area_cluster).then(response => {
            if (response) {
                SwalClose()
                if(this._isMounted) {                    
                    this.setState({listKitTrack: response.data.data.data});
                    this.setState({pageCount: response.data.data.last_page});
                    this.setState({currentPage: response.data.data.current_page});
                    this.setState({per_page: response.data.data.per_page});
                    

                    const OPTIONS  = response.data.data.data.map(row => row.id);
                    //console.log(OPTIONS);
                    this.setState({checkboxes: OPTIONS.reduce(
                      (options, option) => ({
                        ...options,
                        [option]: false
                      }),
                      {}
                    )})
                    
                }
                //console.log(this.state.checkboxes);
            }
        }).catch((error) => {
            SwalClose()
            if (error.response) {
                ErrorPopup(error.response)
            }

        });
    }

    // Get Area type list
    GetAreaTypeList(){
        CommonApi.GetAreaTypeList().then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({area_type_id: [{label: "Select",options:response.data.data}]});
                }                    
            }
        });
    }

    // Get area cluster list 
    areaClusterList(data){
        CommonApi.getAreaClusterList(data).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({area_cluster_id: [{label: "Select",options:response.data.data}]});
                }                    
            }
        });
    }

     // row per page    
    async rowPerPageChange(event) {        
        await this.setState({per_page: event.target.value});
    }

    // page change event
    async handlePageClick(data) {
        const page = data.selected >= 0 ? data.selected + 1 : 0;
        await Promise.resolve(this.setState(() => ({ currentPage: page })));        
        this.getKitDistributionData()
    }

    // on change search
    async handleSearch(searchText) {
        await this.setState({ search: searchText.target.value });
        await Promise.resolve(this.setState(() => ({ currentPage: 1 })));
        this.getKitDistributionData()
    }

    //on change card genarate dropdown
    async verifiedBeneficiaryChange(event) {        
        await this.setState({verified_kit: event.target.value});
        await this.setState({listKitTrack:[]})
        await this.setState({check_all:false})
        this.selectAllCheckboxes(false);
        //this.getKitDistributionData()
    }
    

    /* change active status */
    async statusChange(id){
        Loading()
        this.setState({ isWaiting: true });
        CommonApi.ChangeUserStatus(id).then(response => {
            SwalClose()
            if (response) {                
                this.getKitDistributionData();
                this.setState({ isWaiting: false });
            }
        });
    }

    // Get area list
    areaList(data){
        CommonApi.getAreaList(data).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({area_id: [{label: "Select",options:response.data.data}]});
                }                    
            }
        });
    }

    /* select area type */
    handleMulti = selectedMulti => {
        this.setState({ selectedMulti });
        this.setState({ city_village: selectedMulti.value });
        this.setState({selectedMultiAreaCluster:[]})
        this.areaClusterList(selectedMulti.value);
        this.setState({selectedMultiArea:[]})
        this.areaList(selectedMulti.value);
    };

    /* Select area cluster */
    handleMultiAreaCluster = selectedMultiAreaCluster => {
        this.setState({ selectedMultiAreaCluster });
        this.setState({ area_cluster: selectedMultiAreaCluster.value });
        this.setState({selectedMultiArea:[]})
        this.areaList(selectedMultiAreaCluster.value);
    };

    /* Select area */
    handleMultiArea = selectedMultiArea => {
        this.setState({ selectedMultiArea });
        this.setState({ area_village: selectedMultiArea.value });
    };
    
    /* search button event */
    searchRecord(event){
        this.getKitDistributionData()
    }

    /* checkbox change event */
    handleInputChange(event) {
        //const isChecked = event.target.checked;
        var value = event.target.value;        

        const { id, checked } = event.target;
        this.setState([...isCheck, id]);
        //console.log('Add');
        //console.log(isCheck)
        if (!checked) {
          this.setState(isCheck.filter(item => item !== id));
          //console.log('Single');
        }
    }

    // uncheck all
    async uncheckall(event) {
      let checkboxes = document.getElementsByName('cardStatus');
      for(var i=0, n=checkboxes.length;i<n;i++) {
        checkboxes[i].checked = false;
        var value = checkboxes[i].value;
        this.state.cardStatus.splice(value, 1);
      }
      //console.log(this.state.cardStatus);
    }

    // check all
    async checkall(event) {
        const isChecked = event.target.checked;
        //let checkboxes = document.getElementsByName('cardStatus');
        if(isChecked==true){
            this.selectAllCheckboxes(true);
        }else{           
            this.selectAllCheckboxes(false);
        }     
      
    }

    /* import beneficiary model */
    openVerifyModal(id){
        this.setState({ cardGenerateModalIsOpen: true });
        this.setState({ kit_track_id: id });
    } 
    closeCardGenerateModal = () => this.setState({ cardGenerateModalIsOpen: false });

    // Card genarate request for Beneficiary
    handleKitVerification(event) {
        event.preventDefault();
        const beneficiaryCheck=this.state.kit_track_id;
        console.log(beneficiaryCheck);
        if(beneficiaryCheck==''){
            //console.log('empty');
            ErrorMessage("Please select beneficiary before Verify");

            return false;
        }

        Loading()
        const formData = new FormData(event.target);        
        //Object.keys(beneficiarys).forEach(key => formData.append(key, beneficiarys[key]));
        formData.append('kit_track_id',this.state.kit_track_id);
        /*Object.keys(this.state.checkboxes)
          .filter(checkbox => this.state.checkboxes[checkbox])
          .forEach(checkbox => {            
            formData.append('kit_track_id[]',checkbox);
        });*/        

        CommonApi.verifyKit(formData).then(response => {
            SwalClose()
            
            if (response.data) {
                this.setState({cardGenerateModalIsOpen:false});                
                SuccessPopup(response.data.message)
                this.getKitDistributionData()
                //this.uncheckall()
                this.selectAllCheckboxes(false);
            }
        }).catch((error) => {
            SwalClose()
            if (error.response) {
                ErrorPopup(error.response)
            }

        });      
    }
    

    handleCheckboxChange = changeEvent => {        
        const { name } = changeEvent.target;
        const isChecked = event.target.checked;
        console.log(name);
        this.setState(prevState => ({
          checkboxes: {
            ...prevState.checkboxes,
            [name]: !prevState.checkboxes[name]
          }
        }));            
        
    };

    selectAllCheckboxes = isSelected => {
        //console.log('select all');        
        Object.keys(this.state.checkboxes).forEach(checkbox => {
          
          this.setState(prevState => ({
            checkboxes: {
              ...prevState.checkboxes,
              [checkbox]: isSelected
            }
          }));
        });
      
    };  

    render() {

        const { selectedMulti } = this.state;
        const { selectedMultiArea } = this.state;
        const { selectedMultiAreaCluster } =this.state;        

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Delivered Kit List" breadcrumbItems={this.state.breadcrumbItems} />
                        
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Row>                                            
                                            <Col lg={2}>                                                
                                                <FormGroup>
                                                    <Label for="verified_kit">Already Verified kit List?*</Label>
                                                    <Input type="select" name="verified_kit" onChange={this.verifiedBeneficiaryChange} id="verified_kit value={this.state.verified_kit}"
                                                     >
                                                        <option value="No">No</option>
                                                        <option value="Yes">Yes</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={3}>
                                                <FormGroup className="select2-container">
                                                    <Label className="control-label">Select City/Village* (Area Type)</Label>
                                                    <Select name="area_type"
                                                        value={selectedMulti}
                                                        isMulti={false}
                                                        onChange={this.handleMulti}
                                                        options={this.state.area_type_id}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg={2}>

                                                <FormGroup className="select2-container">
                                                    <Label className="control-label">Select Area Cluster*</Label>
                                                    <Select name="area_cluster"
                                                        value={selectedMultiAreaCluster}
                                                        isMulti={false}
                                                        onChange={this.handleMultiAreaCluster}
                                                        options={this.state.area_cluster_id}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg={3}>
                                                <FormGroup className="select2-container">
                                                    <Label className="control-label">Select Area/Village* (Area)</Label>
                                                    <Select name="area_name"
                                                        value={selectedMultiArea}
                                                        isMulti={false}
                                                        onChange={this.handleMultiArea}
                                                        options={this.state.area_id}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg={1} sm={6}>
                                                <FormGroup>
                                                    <Label for="selectRows">Show</Label>
                                                    <Input type="select" name="selectRows" onChange={this.rowPerPageChange} id="selectRows" value={this.state.per_page}
                                                     >                                                      
                                                      <option value='50'>50</option>
                                                      <option value='100'>100</option>
                                                      <option value='200'>200</option>
                                                      <option value='500'>500</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={1} sm={6}>
                                                <Button className="mt-4" color="primary" onClick={this.searchRecord}>Search</Button>
                                            </Col>                                            
                                        </Row>

                                        <Row>
                                            <Col lg={2} sm={6}>
                                                {/*(this.state.verified_kit)=='No'?(
                                                    <Button className="btn-block" color="success" onClick={this.openCardGenerateModal}>Verify Kit</Button>
                                                    ):null
                                                */}
                                            </Col>                                            

                                            {/* Beneficiary Verify model */}
                                              <Modal isOpen={this.state.cardGenerateModalIsOpen}>
                                                <ModalHeader>Verify Kit</ModalHeader>
                                                <Form onSubmit={this.handleKitVerification}>
                                                <ModalBody>                                           

                                                        <p>Please confirm Verification</p>
                                                    
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button color="secondary" onClick={this.closeCardGenerateModal}>Cancel</Button>{' '}
                                                    <Button type="submit" color="primary">Confirm</Button>                           
                                                </ModalFooter>
                                                </Form>
                                              </Modal>
                                        </Row>

                                        {/*<Row>
                                            <Col lg={2}>
                                                <Row>
                                                    <Col lg={3}>
                                                        
                                                    </Col>
                                                    <Col lg={6}>
                                                        
                                                    </Col>
                                                </Row>                                                 
                                            </Col>
                                            <Col lg={7}>
                                                
                                            </Col>
                                            <Col lg={3}>
                                                 <Row>
                                                    <Col lg={3}>
                                                        <FormGroup className="mt-2">                                    
                                                            <Label for="search">search</Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={9}>
                                                        <Form>
                                                          <FormGroup>                                                            
                                                            <Input type="text" onKeyUp={this.handleSearch} name="search" id="search" placeholder="" />
                                                          </FormGroup>
                                                        </Form>
                                                    </Col>
                                                 </Row>                                                
                                            </Col>

                                        </Row>*/}


        
                                        <div className="table-responsive mt-3">
                                            <Table className="mb-0" striped bordered hover>
                                                <thead className="bg-primary text-white">
                                                    <tr>
                                                        <th>Verify</th>                                                        
                                                        <th>Beneficiary Name</th>
                                                        <th>Area Name</th>                                                        
                                                        <th>Supervisor Name</th>                                                        
                                                        <th>Distribution Date</th>                                                        
                                                        <th>Distribution Time</th>
                                                        <th>Deliverd DateTime</th>
                                                        <th>Deliverd Status</th>                                                  
                                                        <th>Verify Status</th>                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.listKitTrack ?(
                                                            this.state.listKitTrack.map((option,index)=>{
                                                                return <tr key={index}>
                                                                            <td>                                                                                
                                                                                    {/* <Input type="checkbox" name="cardStatus" id={option.id} value={option.id}
                                                                                    onChange={this.handleInputChange}
                                                                                    className="cardStatusCheckbox"                                                                                    
                                                                                    />*/}

                                                                                    {/*<Checkbox
                                                                                      key={option.id}
                                                                                      type="checkbox"
                                                                                      label={option.id}
                                                                                      id={option.id}                                                                                      
                                                                                      isSelected={this.state.checkboxes[option.id]}
                                                                                      onCheckboxChange={this.handleCheckboxChange}
                                                                                    />*/}
                                                                                
                                                                                {(this.state.verified_kit)=='No'?(
                                                                                    <Button className="btn-sm" color="success" onClick={()=>this.openVerifyModal(option.id)}>Verify</Button>
                                                                                    ):null
                                                                                }
                                                                            </td>                                                                          
                                                                            <td>{option.beneficiary_name}</td>
                                                                          <td>{option.area_name}</td>                                                                          
                                                                          <td>{option.supervisor}</td>                                                                          
                                                                          <td>{DateFormat(option.distribution_date)}</td>
                                                                          <td>{option.distribution_from_time} - {option.distribution_to_time}</td>
                                                                          <td>{DateTimeFormat(option.created_at)}</td>
                                                                          <td>{option.submit_status}</td>                                                                 
                                                                          <td>{option.verify_status}</td>                                                                          
                                                                       </tr>
                                                            })
                                                        ): null
                                                    }                                                   
                                                   
                                                    
                                                </tbody>
                                            </Table>
                                        </div>

                                    {/* paginnation */}
                                    <ReactPaginate
                                      previousLabel="&#x276E;"
                                      nextLabel="&#x276F;"
                                      breakLabel={'...'}
                                      breakClassName={'break-me'}
                                      pageCount={this.state.pageCount}
                                      initialPage={this.state.currentPage - 1}
                                      forcePage={this.state.currentPage - 1}
                                      marginPagesDisplayed={2}
                                      pageRangeDisplayed={5}
                                      onPageChange={this.handlePageClick}
                                      containerClassName={'pagination mt-4 float-right'}
                                      activeClassName={'active'}
                                      disableInitialCallback={true}
                                    /> 
        
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default DeliveredKit;