import React, { Component } from "react";
import { Table, Row, Col, Card, CardBody, Container, Button, Form, FormGroup, Label, Input, FormText,
Pagination, PaginationItem, PaginationLink, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput } from "reactstrap";
import ReactDOM from 'react-dom';
import User from '../../apis/User';
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";
import {DateFormat,DateTimeFormat,Loading,SuccessPopup, SwalClose, ErrorPopup,ErrorMessage,Checkbox} from "../../helpers/globalfunctions";
import CommonApi from '../../apis/CommonApi';
import Swal from 'sweetalert2';
import Select from "react-select";
import Compressor from 'compressorjs';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class BeneficiaryCardGenerate extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Beneficiary", link : "#" },
                { title : "Card Generate", link : "#" },
            ],
            listBeneficiary:[],
            pageCount: 1,
            currentPage: 1,
            per_page:500,
            search:'',
            isWaiting:false,            
            selectedMulti: '',
            selectedMultiAreaCluster:'',
            selectedMultiArea: '',
            selectedMultiRoute: '',
            area_name: '',
            values:[],
            area_id:'',
            area_type:'',
            area_type_id:'',
            cardStatus:[],
            checkedItems: new Map(),
            Checked: false,
            city_village:'',
            area_cluster:'',
            area_village:'',
            cardGenerateModalIsOpen:false,
            card_generated:'No',
            check_all:false,
            isCheckAll:false,
            isCheck:[],
            checkboxes: [],
            route_id:[],
        };

        this.handlePageClick = this.handlePageClick.bind(this);
        this.rowPerPageChange = this.rowPerPageChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.statusChange=this.statusChange.bind(this);
        this.handleBeneficiaryCardGenerate = this.handleBeneficiaryCardGenerate.bind(this);
        this.handleMulti = this.handleMulti.bind(this);
        this.handleMultiAreaCluster=this.handleMultiAreaCluster.bind(this);
        this.areaList= this.areaList.bind(this);        
        this.GetAreaTypeList= this.GetAreaTypeList.bind(this);
        this.areaClusterList=this.areaClusterList.bind(this);
        this.searchRecord=this.searchRecord.bind(this);
        this.handleInputChange=this.handleInputChange.bind(this);
        this.uncheckall=this.uncheckall.bind(this);
        this.checkall=this.checkall.bind(this);
        this.cardGeneratedChange=this.cardGeneratedChange.bind(this);
        this.downloadCard=this.downloadCard.bind(this);
        this.handleMultiRoute=this.handleMultiRoute.bind(this);
        //this.handleCheckboxChange=this.handleCheckboxChange.bind(this);
    }

    componentDidMount() {
        this._isMounted = true        
        this.GetAreaTypeList()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //Get Use data
    getBeneficiaryData() {
        Loading()
        CommonApi.beneficiaryNonCardList(this.state.currentPage,this.state.per_page,this.state.search,this.state.city_village,this.state.area_village,this.state.card_generated,this.state.area_cluster).then(response => {
            if (response) {
                SwalClose()
                if(this._isMounted) {                    
                    this.setState({listBeneficiary: response.data.data.data});
                    this.setState({pageCount: response.data.data.last_page});
                    this.setState({currentPage: response.data.data.current_page});
                    this.setState({per_page: response.data.data.per_page});                    

                    /*this.setState({checkboxes: response.data.data.data.id.reduce(
                      (options, option) => ({
                        ...options,
                        [option]: false
                      }),
                      {}
                    )})*/

                    /*this.setState(checkboxes => {
                        // All titles in an array
                        //const ids = 
                        response.data.data.data.map(row => row.id)
                        //console.log(titles);
                        // Add to existing ones
                        //const cartItems = checkboxes.cartItems.concat(titles);

                        
                    });*/

                    //this.setState({checkboxes: response.data.data.data.map(row => row.id)});

                    const OPTIONS  = response.data.data.data.map(row => row.id);
                    //console.log(OPTIONS);
                    this.setState({checkboxes: OPTIONS.reduce(
                      (options, option) => ({
                        ...options,
                        [option]: false
                      }),
                      {}
                    )})
                    
                }
                //console.log(this.state.checkboxes);
            }
        }).catch((error) => {
            SwalClose()
            if (error.response) {
                ErrorPopup(error.response)
            }

        });
    }

    // Get Area type list
    GetAreaTypeList(){
        CommonApi.GetAreaTypeList().then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({area_type_id: [{label: "Select",options:response.data.data}]});
                }                    
            }
        });
    }

    // Get area cluster list 
    areaClusterList(data){
        CommonApi.getAreaClusterList(data).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({area_cluster_id: [{label: "Select",options:response.data.data}]});
                }                    
            }
        });
    }

     // row per page    
    async rowPerPageChange(event) {        
        await this.setState({per_page: event.target.value});
    }

    // page change event
    async handlePageClick(data) {
        const page = data.selected >= 0 ? data.selected + 1 : 0;
        await Promise.resolve(this.setState(() => ({ currentPage: page })));        
        this.getBeneficiaryData()
    }

    // on change search
    async handleSearch(searchText) {
        await this.setState({ search: searchText.target.value });
        await Promise.resolve(this.setState(() => ({ currentPage: 1 })));
        this.getBeneficiaryData()
    }

    //on change card genarate dropdown
    async cardGeneratedChange(event) { 
        var cardGenarateValue=event.target.value;         
        await this.setState({card_generated: event.target.value});
        await this.setState({listBeneficiary:[]})
        await this.setState({check_all:false})
        if(cardGenarateValue=='Yes'){
            await this.setState({per_page:50})
        }else{
            await this.setState({per_page:500})
        }        
        this.selectAllCheckboxes(false);
        //this.getBeneficiaryData()
    }
    

    /* change active status */
    async statusChange(id){
        Loading()
        this.setState({ isWaiting: true });
        CommonApi.ChangeUserStatus(id).then(response => {
            SwalClose()
            if (response) {                
                this.getBeneficiaryData();
                this.setState({ isWaiting: false });
            }
        });
    }

    // Get area list
    areaList(data){
        CommonApi.getAreaList(data).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({area_id: [{label: "Select",options:response.data.data}]});
                }                    
            }
        });
    }

    // Get Route based on area
    getRouteArea(data){
        CommonApi.getRouteArea(data).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({route_id: [{label: "Select",options:response.data.data}]});
                }                    
            }
        });   
    }

    /* select area type */
    handleMulti = selectedMulti => {
        this.setState({ selectedMulti });
        this.setState({ city_village: selectedMulti.value });
        this.setState({selectedMultiAreaCluster:[]})
        this.areaClusterList(selectedMulti.value);
        this.setState({selectedMultiArea:[]})
        this.areaList(selectedMulti.value);
    };

    /* Select area cluster */
    handleMultiAreaCluster = selectedMultiAreaCluster => {
        this.setState({ selectedMultiAreaCluster });
        this.setState({ area_cluster: selectedMultiAreaCluster.value });
        this.setState({selectedMultiArea:[]})
        this.areaList(selectedMultiAreaCluster.value);
    };

    /* Select area */
    handleMultiArea = selectedMultiArea => {
        this.setState({ selectedMultiArea });
        this.setState({ area_village: selectedMultiArea.value });
    };

    /* Select area */
    handleMultiRoute = selectedMultiRoute => {
        this.setState({ selectedMultiRoute });
        this.setState({ route_id: selectedMultiRoute.value });
    };
    
    /* search button event */
    searchRecord(event){
        this.getBeneficiaryData()
    }

    /* checkbox change event */
    handleInputChange(event) {
        //const isChecked = event.target.checked;
        var value = event.target.value;
        //const item = event.target.name;
        //console.log(event.target.value);

        //this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));

        /*if(isChecked){
            this.state.cardStatus[value] = value;   
        }else{
            this.state.cardStatus.splice(value, 1);
        }
        console.log(this.state.cardStatus);*/

        const { id, checked } = event.target;
        this.setState([...isCheck, id]);
        console.log('Add');
        //console.log(isCheck)
        if (!checked) {
          this.setState(isCheck.filter(item => item !== id));
          console.log('Single');
        }
    }

    // uncheck all
    async uncheckall(event) {
      let checkboxes = document.getElementsByName('cardStatus');
      for(var i=0, n=checkboxes.length;i<n;i++) {
        checkboxes[i].checked = false;
        var value = checkboxes[i].value;
        this.state.cardStatus.splice(value, 1);
      }
      console.log(this.state.cardStatus);
    }

    // check all
    async checkall(event) {
        const isChecked = event.target.checked;
        //let checkboxes = document.getElementsByName('cardStatus');
        if(isChecked==true){            
            /*for(var i=0, n=checkboxes.length;i<n;i++) {
                checkboxes[i].checked = true;                
                var value = checkboxes[i].value;
                this.state.cardStatus[value] = value;                
            }
            console.log(this.state.cardStatus);*/
            this.selectAllCheckboxes(true);
        }else{
            /*for(var i=0, n=checkboxes.length;i<n;i++) {
                checkboxes[i].checked = false;
                var value = checkboxes[i].value;
                this.state.cardStatus.splice(value, 1);
            }
            console.log(this.state.cardStatus);*/
            this.selectAllCheckboxes(false);
        }     
      
    }

    /* import beneficiary model */
    openCardGenerateModal = () => {
        this.setState({selectedMultiRoute:[]}) 
        this.setState({ cardGenerateModalIsOpen: true });
        this.getRouteArea(this.state.area_village)
    }
    closeCardGenerateModal = () => this.setState({ cardGenerateModalIsOpen: false });

    // Card genarate request for Beneficiary
    handleBeneficiaryCardGenerate(event) {
        event.preventDefault();
        const beneficiaryCheck=this.state.checkboxes;
        //console.log(beneficiaryCheck);
        /*if(beneficiaryCheck==''){
            //console.log('empty');
            ErrorMessage("Please select beneficiary before generate card");

            return false;
        }*/

        var checkCount=0;
        Object.keys(this.state.checkboxes)
          .filter(checkbox => this.state.checkboxes[checkbox])          
          .forEach(checkbox => {
            //console.log(checkbox, "is selected.");                        
            checkCount++
          }
        );

        if(checkCount <= 0) {            
            ErrorMessage("Please select beneficiary before Generate card");
            return false;
        }

        Loading()
        const formData = new FormData(event.target);        
        //Object.keys(beneficiarys).forEach(key => formData.append(key, beneficiarys[key]));

        Object.keys(this.state.checkboxes)
          .filter(checkbox => this.state.checkboxes[checkbox])
          .forEach(checkbox => {            
            formData.append('beneficiary_id[]',checkbox);
        });        

        CommonApi.generateBeneficiaryCard(formData).then(response => {
            SwalClose()
            
            if (response.data) {
                this.setState({cardGenerateModalIsOpen:false});                
                SuccessPopup(response.data.message)
                this.getBeneficiaryData()
                //this.uncheckall()
                this.selectAllCheckboxes(false);
            }
        }).catch((error) => {
            SwalClose()
            if (error.response) {
                ErrorPopup(error.response)
            }

        });      
    }

    /* download card */
    downloadCard(event){
        event.preventDefault();
        const beneficiaryCheck=this.state.checkboxes;
        //console.log(this.state.checkboxes);
        
        var checkCount=0;
        Object.keys(this.state.checkboxes)
          .filter(checkbox => this.state.checkboxes[checkbox])          
          .forEach(checkbox => {
            //console.log(checkbox, "is selected.");                        
            checkCount++
          }
        );

        if(checkCount <= 0) {            
            ErrorMessage("Please select beneficiary before Download card");
            return false;
        }


        Loading()
        const data = this.state.checkboxes;

        CommonApi.downloadBeneficiaryCard(data).then(response => {
            SwalClose()
            
            if (response.data) {                
                //SuccessPopup(response.data.message)
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
               var fileLink = document.createElement('a');
               fileLink.href = fileURL;
               fileLink.setAttribute('download', 'beneficiary-card.pdf');
               //fileLink.download = 'SamplePDF.pdf';
               document.body.appendChild(fileLink);
               fileLink.click();
               
               this.selectAllCheckboxes(false);
            }
        }).catch((error) => {
            SwalClose()
            if (error.response) {
                ErrorPopup(error.response)
            }

        });
    }

    handleCheckboxChange = changeEvent => {        
        const { name } = changeEvent.target;
        const isChecked = event.target.checked;
        console.log(name);
        this.setState(prevState => ({
          checkboxes: {
            ...prevState.checkboxes,
            [name]: !prevState.checkboxes[name]
          }
        }));            
        
    };

    selectAllCheckboxes = isSelected => {
        //console.log('select all');        
        Object.keys(this.state.checkboxes).forEach(checkbox => {
          
          this.setState(prevState => ({
            checkboxes: {
              ...prevState.checkboxes,
              [checkbox]: isSelected
            }
          }));
        });
        /*Object.keys(this.state.checkboxes)
          .filter(checkbox => this.state.checkboxes[checkbox])
          .forEach(checkbox => {
            console.log(checkbox, "is selected.");
        });*/
    };  

    render() {

        const { selectedMulti } = this.state;
        const { selectedMultiArea } = this.state;
        const { selectedMultiAreaCluster } =this.state;
        const { selectedMultiRoute } = this.state;

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Beneficiary Card Generate" breadcrumbItems={this.state.breadcrumbItems} />
                        
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Row>                                            
                                            <Col lg={2}>                                                
                                                <FormGroup>
                                                    <Label for="card_generated">Already Card Generate?*</Label>
                                                    <Input type="select" name="card_generated" onChange={this.cardGeneratedChange} id="card_generated value={this.state.card_generated}"
                                                     >
                                                        <option value="No">No</option>
                                                        <option value="Yes">Yes</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={3}>
                                                <FormGroup className="select2-container">
                                                    <Label className="control-label">Select City/Village* (Area Type)</Label>
                                                    <Select name="area_type"
                                                        value={selectedMulti}
                                                        isMulti={false}
                                                        onChange={this.handleMulti}
                                                        options={this.state.area_type_id}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg={2}>

                                                <FormGroup className="select2-container">
                                                    <Label className="control-label">Select Area Cluster*</Label>
                                                    <Select name="area_cluster"
                                                        value={selectedMultiAreaCluster}
                                                        isMulti={false}
                                                        onChange={this.handleMultiAreaCluster}
                                                        options={this.state.area_cluster_id}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg={3}>
                                                <FormGroup className="select2-container">
                                                    <Label className="control-label">Select Area/Village* (Area)</Label>
                                                    <Select name="area_name"
                                                        value={selectedMultiArea}
                                                        isMulti={false}
                                                        onChange={this.handleMultiArea}
                                                        options={this.state.area_id}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg={1} sm={6}>
                                                <FormGroup>
                                                    <Label for="selectRows">Show</Label>
                                                    <Input type="select" name="selectRows" onChange={this.rowPerPageChange} id="selectRows" value={this.state.per_page}
                                                     >                                                      
                                                      <option value='50'>50</option>
                                                      <option value='100'>100</option>
                                                      <option value='200'>200</option>
                                                      <option value='500'>500</option>
                                                      <option value='1000'>1000</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={1} sm={6}>
                                                <Button className="mt-4" color="primary" onClick={this.searchRecord}>Search</Button>
                                            </Col>                                            
                                        </Row>

                                        <Row>
                                            <Col lg={2} sm={6}>
                                                {(this.state.card_generated)=='No'?(
                                                    <Button className="btn-block" color="success" onClick={this.openCardGenerateModal}>Generate Card</Button>
                                                    ):(
                                                    <Button className="btn-block" color="dark" onClick={this.downloadCard}>Download Card</Button>
                                                    )
                                                }
                                            </Col>                                            

                                            {/* Beneficiary Card generate model */}
                                              <Modal isOpen={this.state.cardGenerateModalIsOpen}>
                                                <ModalHeader>Generate Card</ModalHeader>
                                                <Form onSubmit={this.handleBeneficiaryCardGenerate}>
                                                <ModalBody>                                           

                                                        {/*<FormGroup>
                                                            <Label for="distribution_date">Select Distribution Date*</Label>
                                                            <Input type="date" name="distribution_date" id="distribution_date" placeholder="Select Distribution Date" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="distribution_from_time">Select Distribution From Time*</Label>
                                                            <Input type="time" name="distribution_from_time" id="distribution_from_time" placeholder="Select Distribution from time" />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="distribution_to_time">Select Distribution To Time*</Label>
                                                            <Input type="time" name="distribution_to_time" id="distribution_to_time" placeholder="Select Distribution To time" />
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Label for="venue">Venue</Label>
                                                            <Input type="text" name="venue" id="venue" placeholder="Enter Venue Name" />
                                                        </FormGroup>*/}

                                                        <FormGroup className="select2-container">
                                                            <Label className="control-label">Select Route*</Label>
                                                            <Select name="route_name"                                                    
                                                                value={selectedMultiRoute}
                                                                isMulti={false}
                                                                onChange={this.handleMultiRoute}
                                                                options={this.state.route_id}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                        </FormGroup>
                                                    
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button color="secondary" onClick={this.closeCardGenerateModal}>Cancel</Button>{' '}
                                                    <Button type="submit" color="primary">Generate</Button>                           
                                                </ModalFooter>
                                                </Form>
                                              </Modal>
                                        </Row>

                                        {/*<Row>
                                            <Col lg={2}>
                                                <Row>
                                                    <Col lg={3}>
                                                        
                                                    </Col>
                                                    <Col lg={6}>
                                                        
                                                    </Col>
                                                </Row>                                                 
                                            </Col>
                                            <Col lg={7}>
                                                
                                            </Col>
                                            <Col lg={3}>
                                                 <Row>
                                                    <Col lg={3}>
                                                        <FormGroup className="mt-2">                                    
                                                            <Label for="search">search</Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={9}>
                                                        <Form>
                                                          <FormGroup>                                                            
                                                            <Input type="text" onKeyUp={this.handleSearch} name="search" id="search" placeholder="" />
                                                          </FormGroup>
                                                        </Form>
                                                    </Col>
                                                 </Row>                                                
                                            </Col>

                                        </Row>*/}


        
                                        <div className="table-responsive mt-3">
                                            <Table className="mb-0" striped bordered hover>
                                                <thead className="bg-primary text-white">
                                                    <tr>
                                                        <th>
                                                            
                                                            <FormGroup check>                                                                
                                                                <Input type="checkbox" name="check_all" id="check_all" defaultChecked={this.state.check_all} value=""
                                                                onChange={this.checkall}
                                                                className="" 
                                                                style={{width: "20px",height: "20px"}}
                                                                />
                                                                <Label for="check_all" className="ml-1 pt-2">All</Label>                                                                            
                                                            </FormGroup>

                                                        </th>                                                        
                                                        <th>Name</th>
                                                        <th>Photo</th>
                                                        <th>Area/Street</th>
                                                        <th>Area/Village</th>
                                                        <th>City/Village</th>
                                                        <th>Mobile</th>
                                                        <th>QR Code</th> 
                                                        <th>Dist Token No</th>
                                                        <th>Card Validity</th>
                                                        <th>Card Generated</th>
                                                        <th>Created By</th>
                                                        <th>Status</th>                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.listBeneficiary ?(
                                                            this.state.listBeneficiary.map((option,index)=>{
                                                                return <tr key={index}>
                                                                            <td>
                                                                                <FormGroup check>
                                                                                    {/* <Input type="checkbox" name="cardStatus" id={option.id} value={option.id}
                                                                                    onChange={this.handleInputChange}
                                                                                    className="cardStatusCheckbox"                                                                                    
                                                                                    />*/}

                                                                                    <Checkbox
                                                                                      key={option.id}
                                                                                      type="checkbox"
                                                                                      label={option.id}
                                                                                      id={option.id}                                                                                      
                                                                                      isSelected={this.state.checkboxes[option.id]}
                                                                                      onCheckboxChange={this.handleCheckboxChange}
                                                                                    />
                                                                                </FormGroup>
                                                                            </td>                                                                          
                                                                          <td>{option.beneficiary_name}</td>
                                                                          <td>{option.attachments?(
                                                                                    option.attachments.map((attValue, attIndex)=>{
                                                                                        return <a key={attIndex} href={(attValue.attachment_name=='beneficiary_photo')?attValue.path:''} target="_blank">{(attValue.attachment_name=='beneficiary_photo')?<img src={attValue.path} width="50px" height="50px" />:''}</a>
                                                                                    })
                                                                                    ):null
                                                                            }</td>
                                                                          <td>{option.street_area}</td>
                                                                          <td>{option.area_name}</td>
                                                                          <td>{option.area_type_name}</td>              
                                                                          <td>{option.mobile_no}</td>
                                                                          <td>{option.zpuid}</td>
                                                                          <td>{(option.card_distribution_token_no)?option.card_distribution_token_no:''}</td>
                                                                          <td>{(option.card_expire_validity)?option.card_expire_validity:''}</td>
                                                                          <td>{option.card_generated}</td>
                                                                          <td>{option.created_by}</td>
                                                                          <td>{option.status}</td>                                                                          
                                                                       </tr>
                                                            })
                                                        ): null
                                                    }                                                   
                                                   
                                                    
                                                </tbody>
                                            </Table>
                                        </div>

                                    {/* paginnation */}
                                    <ReactPaginate
                                      previousLabel="&#x276E;"
                                      nextLabel="&#x276F;"
                                      breakLabel={'...'}
                                      breakClassName={'break-me'}
                                      pageCount={this.state.pageCount}
                                      initialPage={this.state.currentPage - 1}
                                      forcePage={this.state.currentPage - 1}
                                      marginPagesDisplayed={2}
                                      pageRangeDisplayed={5}
                                      onPageChange={this.handlePageClick}
                                      containerClassName={'pagination mt-4 float-right'}
                                      activeClassName={'active'}
                                      disableInitialCallback={true}
                                    /> 
        
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default BeneficiaryCardGenerate;