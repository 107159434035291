import Api from "./api";

export const CommonApi = {
	userlist : () => {
	    return Api.get('/admin/user-list');
	},
  vendorList : () => {
      return Api.get('/admin/vendor-list');
  },
  getSupervisorList:()=>{
    return Api.get('/admin/supervisor-list');
  },
	AddUser : (data) => {
		return Api.post('/admin/add-user',data);
	},  
  downloadUserCard:(data)=>{
    return Api.post('/admin/download-user-card',{'user_id':data},{responseType: 'blob'});
  },
  getRoleList : () => {
      return Api.get('/admin/get-role-list');
  },  
	ChangeUserStatus:(id)=>{
		return Api.post('/admin/user-status-change/'+id);
	},
	// Get village list
	GetAreaTypeList: () => {
	    return Api.get('/admin/get-area-type-list');
	},
	AreaList:(currentPage,per_page,search)=>{
      return Api.get('/admin/system-area-data?page='+currentPage+'&per_page='+per_page+'&search='+search);
  },
  AddArea : (data) => {
		return Api.post('/admin/add-area',data);
	},
  getAreaClusterList:(area_type_id)=>{
    return Api.get('/admin/get-area-cluster-list?area_type_id='+area_type_id);
  },
	getAreaList: (area_cluster_id) => {
	  return Api.get('/admin/get-area-list?area_cluster_id='+area_cluster_id);
	},
	
	/* User Profile change password */
	changePassword: (data) => {
    return Api.post('/admin/change-password',data);
  },

	/*Beneficiary*/
	AddBeneficiary: (data)=>{  		
		return Api.post('/admin/add-beneficiary',data);
	},
  beneficiaryEditData: (id)=>{     
    return Api.post('/admin/edit-beneficiary/'+id);
  },    
	beneficiaryList:(currentPage,per_page,search)=>{
      return Api.get('/admin/beneficiary-list?page='+currentPage+'&per_page='+per_page+'&search='+search);
  },
  importBeneficiary: (data)=>{  		
		return Api.post('/admin/import-beneficiary',data);
	},
  updateBeneficiary: (data)=>{      
    return Api.post('/admin/update-beneficiary',data);
  },
  deleteBeneficiary: (id)=>{      
    return Api.post('/admin/delete-beneficiary/'+id);
  },

  /* Beneficiary Card generate */
	beneficiaryNonCardList:(currentPage,per_page,search,area_type_id,area_id,card_generated,area_cluster)=>{
      return Api.get('/admin/beneficiary-noncard-list?page='+currentPage+'&per_page='+per_page+'&search='+search+'&area_type='+area_type_id+'&area='+area_id+'&card_generated='+card_generated+'&area_cluster='+area_cluster);
  },
  generateBeneficiaryCard: (data)=>{  		
		return Api.post('/admin/generate-beneficiary-card',data);
	},
	downloadBeneficiaryCard:(data)=>{
		return Api.post('/admin/download-beneficiary-card',{'beneficiary_id':data},{responseType: 'blob'});
	},

  /* Route Manage */  
  getRouteNonGenerated:(currentPage,per_page,search,area_type_id,area_cluster)=>{
    return Api.get('/admin/route-non-generated-list?page='+currentPage+'&per_page='+per_page+'&search='+search+'&area_type='+area_type_id+'&area_cluster='+area_cluster);
  },
  generateRoute: (data)=>{      
    return Api.post('/admin/generate-route',data);
  },
  getRouteList:(currentPage,per_page,search)=>{
    return Api.get('/admin/get-route-list?page='+currentPage+'&per_page='+per_page+'&search='+search);
  },
  deleteRoute: (id)=>{      
    return Api.post('/admin/delete-route/'+id);
  },
  getVendorRoute:()=>{
    return Api.get('/admin/get-vendor-route');
  },
  getRouteDetails:(id)=>{
    return Api.get('/admin/get-route-details/'+id);
  },
  getRouteArea:(area_id)=>{
    return Api.get('/admin/get-route-area?area_id='+area_id);
  },  

  /* Kit Manage */
  addKit : (data) => {
    return Api.post('/admin/add-kit',data);
  },
  getKitList:(currentPage,per_page,search)=>{
    return Api.get('/admin/get-kit-list?page='+currentPage+'&per_page='+per_page+'&search='+search);
  },
  deleteKit: (id)=>{      
    return Api.post('/admin/delete-kit/'+id);
  },
  returnKit:(data) => {
    return Api.post('/admin/return-kit',data);
  },

  /* Kit distributon */
  assignKitDistribution: (data) => {
    return Api.post('/admin/assign-kit-distribution',data);
  },
  getKitDistributionData:(currentPage,per_page,search)=>{
    return Api.get('/admin/get-kit-distribution-list?page='+currentPage+'&per_page='+per_page+'&search='+search);
  },
  qrCodeSubmit:(qr_code) => {
    return Api.post('/admin/beneficiary-qr-code-submit',{'qr_code':qr_code});
  },
  getKitDistributionTracking:(currentPage,per_page,search)=>{
    return Api.get('/admin/get-kit-distribution-tracking?page='+currentPage+'&per_page='+per_page+'&search='+search);
  },
  getDeliverdKitList:(currentPage,per_page,search)=>{
    return Api.get('/admin/get-delivered-kit-list?page='+currentPage+'&per_page='+per_page+'&search='+search);
  },  

  /* Report */
  getBeneficiaryReport:(currentPage,per_page,search)=>{
    return Api.get('/admin/beneficiary-report?page='+currentPage+'&per_page='+per_page+'&search='+search);
  },
  getKitStockReport:(currentPage,per_page,search)=>{
    return Api.get('/admin/kit-stock-report?page='+currentPage+'&per_page='+per_page+'&search='+search);
  },
  getAreaCardReport:(currentPage,per_page,search,area_type_id,area_cluster)=>{
    return Api.get('/admin/area-card-report?page='+currentPage+'&per_page='+per_page+'&search='+search+'&area_type='+area_type_id+'&area_cluster='+area_cluster);
  },  
  getTotalCardCount:()=>{
    return Api.get('/admin/total-card-count');
  },
  getDashboardReport:()=>{
    return Api.get('/admin/get-dashboard-report');
  },
  getBeneficiaryVerifyReport:(currentPage,per_page,search,verify_date)=>{
    return Api.get('/admin/beneficiary-verify-report?page='+currentPage+'&per_page='+per_page+'&search='+search+'&verify_date='+verify_date);
  },
  getKitVerifyReport:(currentPage,per_page,search,verify_date)=>{
    return Api.get('/admin/kit-verify-report?page='+currentPage+'&per_page='+per_page+'&search='+search+'&verify_date='+verify_date);
  },

  /* Verifiyer */
  beneficiaryNonOrVerifiyList:(currentPage,per_page,search,area_type_id,area_id,verified_beneficiary,area_cluster)=>{
      return Api.get('/admin/beneficiary-non-or-verify-list?page='+currentPage+'&per_page='+per_page+'&search='+search+'&area_type='+area_type_id+'&area='+area_id+'&verified_beneficiary='+verified_beneficiary+'&area_cluster='+area_cluster);
  },  
  verifyBeneficiary: (data)=>{      
    return Api.post('/admin/verify-beneficiary',data);
  },
  kitDistributionNonOrVerifiyList:(currentPage,per_page,search,area_type_id,area_id,verified_kit,area_cluster)=>{
      return Api.get('/admin/kit-non-or-verify-list?page='+currentPage+'&per_page='+per_page+'&search='+search+'&area_type='+area_type_id+'&area='+area_id+'&verified_kit='+verified_kit+'&area_cluster='+area_cluster);
  },
  verifyKit: (data)=>{      
    return Api.post('/admin/verify-kit',data);
  },
  getVehicleList:() => {
    return Api.get('/admin/get-vehicle');
  },
  vehicleOutKM:(data) => {
    return Api.post('/admin/vehicle-out-km',data);
  }

};

export default CommonApi;