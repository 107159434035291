import React, { Component } from 'react';
import { Container, Row, Col, Table, Card, CardBody, Button, Form, FormGroup, Label, Input, FormText,
Pagination, PaginationItem, PaginationLink } from "reactstrap";
//import MiniWidgets from "./MiniWidgets";
//import RevenueAnalytics from "./RevenueAnalytics";
import CommonApi from '../../apis/CommonApi';


//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
//import Stats from '../../apis/Stats';
let isMounted = false;
class VendorDashboard extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state={
            breadcrumbItems : [
                { title : "Dashboard", link : "#" },                
            ],            
            vendor_stock_report:[],
        }
    }

    componentDidMount(){
        this._isMounted = true;
        this.getDashboardReport()
        
    }

    getDashboardReport() {
        CommonApi.getDashboardReport().then(response => {
            if (response) {
                if(this._isMounted) {                    
                    this.setState({vendor_stock_report: response.data.data.vendor_stock_report});                    
                }
            }
        });
    }


    render() {        
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Dashboard" breadcrumbItems={this.state.breadcrumbItems} />

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <center><h5>My Kit Stock Report</h5></center>
                                        <div className="table-responsive">
                                            <Table className="mb-0" striped bordered hover>
                                                <thead className="bg-primary text-white">
                                                    <tr>                                                        
                                                        <th>Vendor Name</th>
                                                        <th>Total Stock</th>                                                        
                                                        <th>Send Stock</th>
                                                        <th>Return Back</th>                                                        
                                                        <th>Available Stock</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                   {
                                                        this.state.vendor_stock_report ?(
                                                            this.state.vendor_stock_report.map((option,index)=>{
                                                                return <tr key={index}>                                                                          
                                                                          <td>{option.vendor_name}</td>
                                                                          <td>{option.total_kit_stock}</td>
                                                                          <td>{option.on_way_stock}</td>
                                                                          <td>{option.return_back}</td>                                                                          
                                                                          <td>{option.available_stock}</td>
                                                                       </tr>
                                                            })
                                                        ): null
                                                    }
                                                    
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                                                                       

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default VendorDashboard;
