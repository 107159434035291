import React, { Component } from "react";
import { Table, Row, Col, Card, CardBody, Container, Button, Form, FormGroup, Label, Input, FormText,
Pagination, PaginationItem, PaginationLink, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput } from "reactstrap";
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";
import {DateFormat,DateTimeFormat,Loading,SuccessPopup, SwalClose, ErrorPopup, ErrorMessage} from "../../helpers/globalfunctions";
import CommonApi from '../../apis/CommonApi';
import Swal from 'sweetalert2';
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class KitDistribution extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Kit", link : "#" },
                { title : "Kit Distribution", link : "#" },
            ],
            listKitDistribution:[],
            pageCount: 1,
            currentPage: 1,
            per_page:100,
            search:'',
            isWaiting:false,
            modalIsOpen: false,
            selectedMulti: null,
            route_id:[],
            supervisor:[],
            route_details:[],
        };

        this.handlePageClick = this.handlePageClick.bind(this);
        this.rowPerPageChange = this.rowPerPageChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.statusChange=this.statusChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.deleteConfirmModal=this.deleteConfirmModal.bind(this);
        this.handleDeleteKit=this.handleDeleteKit.bind(this);
        this.handleMulti = this.handleMulti.bind(this);
        this.openModal = this.openModal.bind(this);
        this.getVendorRoute = this.getVendorRoute.bind(this);
    }

    componentDidMount() {
        this._isMounted = true
        this.getKitDistributionData()
        this.getSupervisorList()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //Get kit distribution data
    getKitDistributionData() {
        CommonApi.getKitDistributionData(this.state.currentPage,this.state.per_page,this.state.search).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({listKitDistribution: response.data.data.data});
                    this.setState({pageCount: response.data.data.last_page});
                    this.setState({currentPage: response.data.data.current_page});
                    this.setState({per_page: response.data.data.per_page});
                }
            }
        });
    }

    //get Supervisor list for generate route
    getSupervisorList(){
        CommonApi.getSupervisorList().then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({supervisor: response.data.data});
                }                    
            }
        });
    }

    // Get Route list for vendor
    getVendorRoute(){
        CommonApi.getVendorRoute().then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({route_id: [{label: "Select",options:response.data.data}]});
                }                    
            }
        });
    }

    // Get Route Details
    getRouteDetails(id){
        CommonApi.getRouteDetails(id).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({route_details: response.data.data});
                }                    
            }
        });
    }
    

     // row per page    
    async rowPerPageChange(event) {        
        await this.setState({per_page: event.target.value});        
        this.getKitDistributionData()
    }

    // page change event
    async handlePageClick(data) {
        const page = data.selected >= 0 ? data.selected + 1 : 0;
        await Promise.resolve(this.setState(() => ({ currentPage: page })));        
        this.getKitDistributionData()
    }

    // on change search
    async handleSearch(searchText) {
        await this.setState({ search: searchText.target.value });
        await Promise.resolve(this.setState(() => ({ currentPage: 1 })));
        this.getKitDistributionData()
    }
    

    /* change active status */
    async statusChange(id){
        Loading()
        this.setState({ isWaiting: true });
        CommonApi.ChangeUserStatus(id).then(response => {
            SwalClose()
            if (response) {                
                this.getKitDistributionData();
                this.setState({ isWaiting: false });
            }
        });
    }    

    openModal(){
        this.setState({ modalIsOpen: true });
        //this.getVendorRoute()
    }
    closeModal = () => this.setState({ modalIsOpen: false });

    /* select route */
    handleMulti = selectedMulti => {        
        this.setState({ selectedMulti });
        this.getRouteDetails(selectedMulti.value)
    };

    // Assign Kit Distribution
    handleSubmit(event) {
        event.preventDefault();
        Loading()
        const data = new FormData(event.target);
        CommonApi.assignKitDistribution(data).then(response => {
            SwalClose()
            
            if (response.data.status==1) {
                this.setState({modalIsOpen:false});
                SuccessPopup(response.data.message)
                this.getKitDistributionData();
            }else{
                ErrorMessage(response.data.message)
            }
        }).catch((error) => {
            SwalClose()
            if (error.response) {
                ErrorPopup(error.response)
            }

        });      
    }
    /* Delete Confirm Modal */
    async deleteConfirmModal(id){
        await this.setState({deleteModalIsOpen:true});
        await this.setState({delete_id:id});
    }
    /* Delete function */
    handleDeleteKit(event){
        event.preventDefault();
        Loading()        

        CommonApi.deleteKit(this.state.delete_id).then(response => {
            SwalClose()
            
            if (response.data.status==1) {
                this.setState({deleteModalIsOpen:false});
                SuccessPopup(response.data.message)
                this.getKitDistributionData();
            }
        }).catch((error) => {
            SwalClose()
            if (error.response) {
                ErrorPopup(error.response)
            }

        });
    }

    render() {
        const { selectedMulti } = this.state;

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Kit Distribution" breadcrumbItems={this.state.breadcrumbItems} />
                        

                        <Row>
                            <Col lg={12} className="mb-2">
                                <div>
                                  <Button color="primary" onClick={this.openModal}>Assign Distribution</Button>
                                  <Modal isOpen={this.state.modalIsOpen}>
                                    <ModalHeader>Assign Distribution</ModalHeader>
                                    <Form onSubmit={this.handleSubmit}>
                                    <ModalBody>

                                            <FormGroup>
                                                <Label for="user_id">Select Supervisor*</Label>
                                                <Input type="select" name="user_id" id="user_id">
                                                  <option value="">--Select Supervisor--</option>                                                  
                                                  {
                                                        this.state.supervisor ?(
                                                            this.state.supervisor.map((option,index)=>{
                                                                return <option key={index} value={option.id}>{option.first_name}</option>
                                                            })
                                                        ): null
                                                    }
                                                </Input>
                                            </FormGroup>

                                            { /*<FormGroup className="select2-container">
                                                <Label className="control-label">Select Route*</Label>
                                                <Select name="route_name"                                                    
                                                    value={selectedMulti}
                                                    isMulti={false}
                                                    onChange={this.handleMulti}
                                                    options={this.state.route_id}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </FormGroup> */ }                                           

                                            { /* <FormGroup>
                                                <div><Label for="total_kit_dist">Distribution Date: {(this.state.route_details!='')?DateFormat(this.state.route_details.distribution_date):null}</Label></div>
                                                <div><Label for="total_kit_dist">Distribution From Time: {(this.state.route_details!='')?this.state.route_details.distribution_from_time:''}</Label></div>
                                                <div><Label for="total_kit_dist">Distribution To Time: {(this.state.route_details!='')?this.state.route_details.distribution_to_time:''}</Label></div>
                                            </FormGroup> */ }                                           

                                            <FormGroup>
                                                <Label for="total_kit_dist">Total Distribution kit qunatity*</Label>
                                                <Input type="text" name="total_kit_dist" id="total_kit_dist" placeholder="Total number of kit" />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="driver_name">Driver Name</Label>
                                                <Input type="text" name="driver_name" id="driver_name" placeholder="Enter Driver Name" />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="vehicle_number">Vehicle Number</Label>
                                                <Input type="text" name="vehicle_number" id="vehicle_number" placeholder="Enter Vehicle Number" />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="vehicle_in_km">Vehicle In Kilometer</Label>
                                                <Input type="text" name="vehicle_in_km" id="vehicle_in_km" placeholder="Enter Vehicle In Kilometer" />
                                            </FormGroup>
                                            
                                        
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="secondary" onClick={this.closeModal}>Cancel</Button>{' '}
                                        <Button type="submit" color="primary">Save</Button>                           
                                    </ModalFooter>
                                    </Form>
                                  </Modal>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {/* deleteModal */ }
                            <Modal isOpen={this.state.deleteModalIsOpen}>
                                <ModalHeader>Delete Kit stock? </ModalHeader>
                                <Form onSubmit={this.handleDeleteKit}>
                                <ModalBody>
                                    <p>Are you sure you want to delete Stock?</p>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={()=>this.setState({deleteModalIsOpen:false})}>Cancel</Button>{' '}
                                    <Button type="submit" color="primary">Delete</Button>                           
                                </ModalFooter>
                                </Form>
                            </Modal>
                        </Row>
                        
                        
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>                                       

                                        <Row>
                                            <Col lg={2}>
                                                <Row>
                                                    <Col lg={3}>
                                                        <FormGroup className="mt-2">                                                    
                                                            <Label for="selectRows">Show</Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <FormGroup>                                                    
                                                            <Input type="select" name="selectRows" onChange={this.rowPerPageChange} id="selectRows" value={this.state.per_page}
                                                             >                                                              
                                                              <option value='50'>50</option>
                                                              <option value='100'>100</option>
                                                              <option value='200'>200</option>
                                                              <option value='500'>500</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>                                                 
                                            </Col>
                                            <Col lg={7}>
                                                {/*<Button>Print</Button>*/}
                                            </Col>
                                            <Col lg={3}>
                                                 <Row>
                                                    <Col lg={3}>
                                                        <FormGroup className="mt-2">                                    
                                                            <Label for="search">search</Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={9}>
                                                        <Form>
                                                          <FormGroup>                                                            
                                                            <Input type="text" onKeyUp={this.handleSearch} name="search" id="search" placeholder="" />
                                                          </FormGroup>
                                                        </Form>
                                                    </Col>
                                                 </Row>                                                
                                            </Col>

                                        </Row>


        
                                        <div className="table-responsive">
                                            <Table className="mb-0" striped bordered hover>
                                                <thead className="bg-primary text-white">
                                                    <tr>                                                        
                                                        <th>Vendor Name</th>
                                                        <th>Supervisor Name</th>                                                        
                                                        <th>Driver Name</th>
                                                        <th>Vehicle Number</th>
                                                        <th>Total Kit distribution</th>
                                                        <th>Assign Date Time</th>
                                                        <th>KM In</th>
                                                        <th>KM Out</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.listKitDistribution ?(
                                                            this.state.listKitDistribution.map((option,index)=>{
                                                                return <tr key={index}>                                                                          
                                                                          <td>{option.vendor_name}</td>
                                                                          <td>{option.supervisore}</td>                                                                          
                                                                          <td>{option.driver_name}</td>
                                                                          <td>{option.vehicle_number}</td>
                                                                          <td>{option.total_kit_dist}</td>
                                                                          <td>{DateTimeFormat(option.created_at)}</td>                                                                          
                                                                          <td>{option.vehicle_in_km}</td>
                                                                          <td>{option.vehicle_out_km}</td>
                                                                       </tr>
                                                            })
                                                        ): null
                                                    }
                                                   
                                                    
                                                </tbody>
                                            </Table>
                                        </div>

                                    {/* paginnation */}
                                    <ReactPaginate
                                      previousLabel="&#x276E;"
                                      nextLabel="&#x276F;"
                                      breakLabel={'...'}
                                      breakClassName={'break-me'}
                                      pageCount={this.state.pageCount}
                                      initialPage={this.state.currentPage - 1}
                                      forcePage={this.state.currentPage - 1}
                                      marginPagesDisplayed={2}
                                      pageRangeDisplayed={5}
                                      onPageChange={this.handlePageClick}
                                      containerClassName={'pagination mt-4 float-right'}
                                      activeClassName={'active'}
                                      disableInitialCallback={true}
                                    /> 
        
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default KitDistribution;