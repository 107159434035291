import React, { Component } from "react";
import { Table, Row, Col, Card, CardBody, Container, Button, Form, FormGroup, Label, Input, FormText,
Pagination, PaginationItem, PaginationLink, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput } from "reactstrap";
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";
import {Today,DateFormat,DateTimeFormat,Loading,SuccessPopup, SwalClose, ErrorPopup} from "../../helpers/globalfunctions";
import CommonApi from '../../apis/CommonApi';
import Swal from 'sweetalert2';
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class BeneficiaryVerifyReport extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Report", link : "#" },
                { title : "Verify", link : "#" },
            ],
            listBeneficiary:[],
            pageCount: 1,
            currentPage: 1,
            per_page:100,
            search:'',
            isWaiting:false,
            modalIsOpen: false,
            selectedMulti: null,
            route_id:[],
            supervisor:[],
            route_details:[],
            verify_date: Today().format('YYYY-MM-DD'),
        };

        this.handlePageClick = this.handlePageClick.bind(this);
        this.rowPerPageChange = this.rowPerPageChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.statusChange=this.statusChange.bind(this);        
        this.handleMulti = this.handleMulti.bind(this);
        this.openModal = this.openModal.bind(this);
        this.getRouteList = this.getRouteList.bind(this);
        this.dateChange = this.dateChange.bind(this);
    }

    componentDidMount() {
        this._isMounted = true
        this.getBeneficiaryVerifyReport()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //Get kit distribution tracking data
    getBeneficiaryVerifyReport() {
        CommonApi.getBeneficiaryVerifyReport(this.state.currentPage,this.state.per_page,this.state.search,this.state.verify_date).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({listBeneficiary: response.data.data.data});
                    this.setState({pageCount: response.data.data.last_page});
                    this.setState({currentPage: response.data.data.current_page});
                    this.setState({per_page: response.data.data.per_page});
                }
            }
        });
    }

    //get Supervisor list for generate route
    getSupervisorList(){
        CommonApi.getSupervisorList().then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({supervisor: response.data.data});
                }                    
            }
        });
    }

    // Get Route list
    getRouteList(){
        CommonApi.getRouteList().then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({route_id: [{label: "Select",options:response.data.data}]});
                }                    
            }
        });
    }

    // Get Route Details
    getRouteDetails(id){
        CommonApi.getRouteDetails(id).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({route_details: response.data.data});
                }                    
            }
        });
    }
    
    async dateChange(e){
        await this.setState({verify_date: event.target.value});        
        this.getBeneficiaryVerifyReport()
         
    }

    // row per page
    async rowPerPageChange(event) {        
        await this.setState({per_page: event.target.value});        
        this.getBeneficiaryVerifyReport()
    }

    // page change event
    async handlePageClick(data) {
        const page = data.selected >= 0 ? data.selected + 1 : 0;
        await Promise.resolve(this.setState(() => ({ currentPage: page })));        
        this.getBeneficiaryVerifyReport()
    }

    // on change search
    async handleSearch(searchText) {
        await this.setState({ search: searchText.target.value });
        await Promise.resolve(this.setState(() => ({ currentPage: 1 })));
        this.getBeneficiaryVerifyReport()
    }
    

    /* change active status */
    async statusChange(id){
        Loading()
        this.setState({ isWaiting: true });
        CommonApi.ChangeUserStatus(id).then(response => {
            SwalClose()
            if (response) {                
                this.getBeneficiaryVerifyReport();
                this.setState({ isWaiting: false });
            }
        });
    }    

    openModal(){
        this.setState({ modalIsOpen: true });
        this.getRouteList()
    }
    closeModal = () => this.setState({ modalIsOpen: false });

    /* select route */
    handleMulti = selectedMulti => {        
        this.setState({ selectedMulti });
        this.getRouteDetails(selectedMulti.value)
    };

    
    render() {
        const { selectedMulti } = this.state;

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Beneficiary Verify Report" breadcrumbItems={this.state.breadcrumbItems} />
                        
                        <Row>
                            <Col lg={12} className="mb-2">
                                <div>                                  
                                  <Link to="/admin-panel/beneficiary-verify-report" className="btn btn-primary">Beneficiary Verify Report</Link>
                                  <Link to="/admin-panel/kit-verify-report" className="btn btn-primary ml-2">Kit Verify Report</Link>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>                                       

                                        <Row>
                                            <Col lg={2}>
                                                <Row>
                                                    <Col lg={3}>
                                                        <FormGroup className="mt-2">                                                    
                                                            <Label for="selectRows">Show</Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <FormGroup>                                                    
                                                            <Input type="select" name="selectRows" onChange={this.rowPerPageChange} id="selectRows" value={this.state.per_page}
                                                             >                                                              
                                                              <option value='50'>50</option>
                                                              <option value='100'>100</option>
                                                              <option value='200'>200</option>
                                                              <option value='500'>500</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>                                                 
                                            </Col>
                                            <Col lg={7}>
                                                <Row>
                                                    <Col lg={3}>
                                                        <FormGroup className="mt-2">                                                    
                                                            <Label for="verify_date">Search by Date filter</Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <FormGroup>                                                            
                                                            <Input type="date" name="verify_date" onChange={this.dateChange} id="verify_date" placeholder="Select Verify Date" defaultValue={(this.state.verify_date)?this.state.verify_date:''} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={3}>
                                                 <Row>
                                                    <Col lg={3}>
                                                        <FormGroup className="mt-2">                                    
                                                            <Label for="search">search</Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={9}>
                                                        <Form>
                                                          <FormGroup>                                                            
                                                            <Input type="text" onKeyUp={this.handleSearch} name="search" id="search" placeholder="" />
                                                          </FormGroup>
                                                        </Form>
                                                    </Col>
                                                 </Row>                                                
                                            </Col>

                                        </Row>


        
                                        <div className="table-responsive mt-3">
                                            <Table className="mb-0" striped bordered hover>
                                                <thead className="bg-primary text-white">
                                                    <tr>                                                                                                              
                                                        <th>Name</th>                                                        
                                                        <th>Area/Street</th>
                                                        <th>Area/Village</th>
                                                        <th>City/Village</th>
                                                        <th>Mobile</th>
                                                        <th>QR Code</th>                                                        
                                                        <th>Verify Status</th>
                                                        <th>Verify By</th>
                                                        <th>Status</th>                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.listBeneficiary ?(
                                                            this.state.listBeneficiary.map((option,index)=>{
                                                                return <tr key={index}>                                                                                                                                                      
                                                                          <td>{option.beneficiary_name}</td>                                                                          
                                                                          <td>{option.street_area}</td>
                                                                          <td>{option.area_name}</td>
                                                                          <td>{option.area_type_name}</td>              
                                                                          <td>{option.mobile_no}</td>
                                                                          <td>{option.zpuid}</td>                                                                          
                                                                          <td>{option.verify_status}</td>
                                                                          <td>{option.confirm_by}</td>
                                                                          <td>{option.status}</td>                                                                          
                                                                       </tr>
                                                            })
                                                        ): null
                                                    }                                                   
                                                   
                                                    
                                                </tbody>
                                            </Table>
                                        </div>

                                    {/* paginnation */}
                                    <ReactPaginate
                                      previousLabel="&#x276E;"
                                      nextLabel="&#x276F;"
                                      breakLabel={'...'}
                                      breakClassName={'break-me'}
                                      pageCount={this.state.pageCount}
                                      initialPage={this.state.currentPage - 1}
                                      forcePage={this.state.currentPage - 1}
                                      marginPagesDisplayed={2}
                                      pageRangeDisplayed={5}
                                      onPageChange={this.handlePageClick}
                                      containerClassName={'pagination mt-4 float-right'}
                                      activeClassName={'active'}
                                      disableInitialCallback={true}
                                    /> 
        
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default BeneficiaryVerifyReport;