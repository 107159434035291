import axios from "axios";

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Accept'] = 'application/json';

const Api = axios.create({
    baseURL: `/api/`, // the url of our server
    timeout: 500000,
    headers: {
        "Content-Type": "application/json"
    }
})
// axios.defaults.baseURL = `/api`;

Api.interceptors.request.use(function(config) {
    const token = localStorage.getItem("user.access_token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
});

/* Add interceptors for response */
Api.interceptors.response.use(
    response => { return response;    },
    error => {        
        if (error.response.status === 401) {
            //store.commit('setUserAuthorised', false);
            localStorage.clear();            
            window.location = '/admin/login';
        }        
        return Promise.reject(error);
        
	}
);

export default Api;