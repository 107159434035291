import React, { Component } from 'react';
import { Col, Card, CardBody, Media } from "reactstrap";

class MiniWidgets extends Component {
    render() {
        return (
            <React.Fragment>
                {
                    this.props.reports.map((report, key) =>
                                    <Col key={key} md={report.width ? report.width : 3}>
                                        <Card>
                                            <CardBody>
                                                <Media>
                                                    <Media body className="overflow-hidden">
                                                        <h4 className="card-title font-size-14 mb-2">{report.title}</h4>
                                                        <h5 className="mb-0 font-size-16">{report.value}</h5>

                                                        {report.data ? <div className={'mt-4'}><br/></div> : null}
                                                        {report.data ? report.data.map((row,key)=>{
                                                            return <div key={key} className={'d-flex justify-content-between '}>
                                                                <p className="">{row.title}</p>
                                                                <p className="mb-0 font-weight-bold ">{row.value}</p>
                                                            </div>
                                                        }) : null}

                                                    </Media>
                                                    <div className="text-primary">
                                                        <i className={report.icon + " font-size-24"}></i>
                                                    </div>
                                                </Media>
                                            </CardBody>
                                            {report.footer ? 
                                            <CardBody className="border-top py-3">
                                                <div className="text-truncate">
                                                    {report.footer}
                                                </div>
                                            </CardBody>
                                        : 
                                            <CardBody className=" py-3">
                                                <div className="text-truncate">
                                                </div>
                                            </CardBody>
                                            }
                                        </Card>
                                    </Col>
                    )
                }
            </React.Fragment>
        );
    }
}

export default MiniWidgets;