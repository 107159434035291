import React from "react";
import { Route, Redirect } from "react-router-dom";

const AppRoute = ({
	component: Component,
	layout: Layout,
	isAuthProtected,
	isRole,
	...rest
}) => (
		<Route
			{...rest}
			render={props => {				
				if (isAuthProtected && !localStorage.getItem("user.access_token")) {
					return (
						<Redirect to={{ pathname: "/admin-panel/login", state: { from: props.location } }} />
					);
				}/*else if(isAuthProtected && isRole=='user'){
					return (
						<Redirect to={{ pathname: "/admin-panel/user-dashboard", state: { from: props.location } }} />
					);
				}*/

				return (
					<Layout>
						<Component {...props} />
					</Layout>
				);
			}}
		/>
	);

export default AppRoute;

