import React, { Component } from "react";
import { Table, Row, Col, Card, CardBody, Container, Button, Form, FormGroup, Label, Input, FormText,
Pagination, PaginationItem, PaginationLink, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput } from "reactstrap";
import ReactDOM from 'react-dom';
import User from '../../apis/User';
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";
import {DateFormat,DateTimeFormat,Loading,SuccessPopup, SwalClose, ErrorPopup} from "../../helpers/globalfunctions";
import CommonApi from '../../apis/CommonApi';
import Swal from 'sweetalert2';
import Select from "react-select";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class SystemArea extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Area", link : "#" },
                { title : "System Area", link : "#" },
            ],
            Arealist:[],
            pageCount: 1,
            currentPage: 1,
            per_page:100,
            search:'',
            isWaiting:false,
            modalIsOpen: false,
            area_type: '',
            values:[],
            selectedGroup: [],
            selectedMulti: null,
            selectedMultiAreaCluster: null,
            max_len : 25,
            area_type_id:'',
            area_cluster_id:'',
        };

        this.handlePageClick = this.handlePageClick.bind(this);
        this.rowPerPageChange = this.rowPerPageChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.statusChange=this.statusChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelectGroup = this.handleSelectGroup.bind(this);
        this.handleMulti = this.handleMulti.bind(this);
        this.GetAreaTypeList= this.GetAreaTypeList.bind(this);
        this.areaClusterList=this.areaClusterList.bind(this);
        this.openModal =this.openModal.bind(this);

    }

    componentDidMount() {
        this._isMounted = true
        this.GetAreaList()
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    // Get Area type list
    GetAreaTypeList(){
        CommonApi.GetAreaTypeList().then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({area_type_id: [{label: "Select",options:response.data.data}]});
                }                    
            }
        });
    }

    //Get Area data
    GetAreaList() {
        CommonApi.AreaList(this.state.currentPage,this.state.per_page,this.state.search).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({Arealist: response.data.data.data});
                    this.setState({pageCount: response.data.data.last_page});
                    this.setState({currentPage: response.data.data.current_page});
                    this.setState({per_page: response.data.data.per_page});
                }
            }
        });
    }

    // Get area cluster list 
    areaClusterList(data){
        CommonApi.getAreaClusterList(data).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({area_cluster_id: [{label: "Select",options:response.data.data}]});
                }                    
            }
        });
    }

     // row per page    
    async rowPerPageChange(event) {        
        await this.setState({per_page: event.target.value});        
        this.GetAreaList()
    }

    // page change event
    async handlePageClick(data) {
        const page = data.selected >= 0 ? data.selected + 1 : 0;
        await Promise.resolve(this.setState(() => ({ currentPage: page })));        
        this.GetAreaList()
    }

    // on change search
    async handleSearch(searchText) {
        await this.setState({ search: searchText.target.value });
        await Promise.resolve(this.setState(() => ({ currentPage: 1 })));
        this.GetAreaList()
    }
    

    /* change active status */
    async statusChange(id){
        Loading()
        this.setState({ isWaiting: true });
        CommonApi.ChangeUserStatus(id).then(response => {
            SwalClose()
            if (response) {                
                this.GetAreaList();
                this.setState({ isWaiting: false });
            }
        });
    }    

    //openModal = () => this.setState({ modalIsOpen: true });
    openModal(){
        this.setState({ modalIsOpen: true });
        this.GetAreaTypeList();
        this.areaClusterList();
    }    
    closeModal = () => this.setState({ modalIsOpen: false });

    //Select
    handleSelectGroup = selectedGroup => {
        this.setState({ selectedGroup });
    };
    handleMulti = selectedMulti => {
        this.setState({ selectedMulti });
        this.setState({selectedMultiAreaCluster:[]})
        this.areaClusterList(selectedMulti.value);
    };

    /* Select area cluster */
    handleMultiAreaCluster = selectedMultiAreaCluster => {
        this.setState({ selectedMultiAreaCluster });
        this.setState({selectedMultiArea:[]})        
    };

    // Add area API
    handleSubmit(event) {
        event.preventDefault();
        Loading()
        const data = new FormData(event.target);
        CommonApi.AddArea(data).then(response => {
            SwalClose()
            
            if (response.data) {
                this.setState({modalIsOpen:false});
                SuccessPopup(response.data.message)
                this.GetAreaList();
            }
        }).catch((error) => {
            SwalClose()
            if (error.response) {
                ErrorPopup(error.response)
            }

        });      
    }

    render() {
        const { selectedGroup } = this.state;
        const { selectedMulti } = this.state;
        const { selectedMultiAreaCluster } = this.state;

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="System Area" breadcrumbItems={this.state.breadcrumbItems} />
                        

                        <Row>
                            <Col lg={12} className="mb-2">
                                <div>
                                  <Button color="primary" onClick={this.openModal}>Add New Area</Button>
                                  <Modal isOpen={this.state.modalIsOpen}>
                                    <ModalHeader>Add Area</ModalHeader>
                                    <Form onSubmit={this.handleSubmit}>
                                    <ModalBody>                                            

                                            <FormGroup className="select2-container">
                                                <Label className="control-label">City/Village*</Label>
                                                <Select name="area_type"
                                                    value={selectedMulti}
                                                    isMulti={false}
                                                    onChange={this.handleMulti}
                                                    options={this.state.area_type_id}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </FormGroup>

                                            <FormGroup className="select2-container">
                                                <Label className="control-label">Select Area Cluster*</Label>
                                                <Select name="area_cluster"
                                                    value={selectedMultiAreaCluster}
                                                    isMulti={false}
                                                    onChange={this.handleMultiAreaCluster}
                                                    options={this.state.area_cluster_id}
                                                    classNamePrefix="select2-selection"
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="area_name">Area/Village Name*</Label>
                                                <Input type="text" name="area_name" id="area_name" placeholder="Enter Area/Village Name" />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="notes">Notes</Label>
                                                <Input type="text" name="notes" id="notes" placeholder="Referance details if require" />
                                            </FormGroup>
                                        
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="secondary" onClick={this.closeModal}>Cancel</Button>{' '}
                                        <Button type="submit" color="primary">Save</Button>                           
                                    </ModalFooter>
                                    </Form>
                                  </Modal>
                                </div>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>                                       

                                        <Row>
                                            <Col lg={2}>
                                                <Row>
                                                    <Col lg={3}>
                                                        <FormGroup className="mt-2">                                                    
                                                            <Label for="selectRows">Show</Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <FormGroup>                                                    
                                                            <Input type="select" name="selectRows" onChange={this.rowPerPageChange} id="selectRows" value={this.state.per_page}
                                                             >
                                                              <option value='50'>50</option>
                                                              <option value='100'>100</option>
                                                              <option value='200'>200</option>
                                                              <option value='500'>500</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>                                                 
                                            </Col>
                                            <Col lg={7}>
                                                {/*<Button>Print</Button>*/}
                                            </Col>
                                            <Col lg={3}>
                                                 <Row>
                                                    <Col lg={3}>
                                                        <FormGroup className="mt-2">                                    
                                                            <Label for="search">search</Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={9}>
                                                        <Form>
                                                          <FormGroup>                                                            
                                                            <Input type="text" onKeyUp={this.handleSearch} name="search" id="search" placeholder="" />
                                                          </FormGroup>
                                                        </Form>
                                                    </Col>
                                                 </Row>                                                
                                            </Col>

                                        </Row>


        
                                        <div className="table-responsive">
                                            <Table className="mb-0" striped bordered hover>
                                                <thead className="bg-primary text-white">
                                                    <tr>
                                                        <th>Area Index</th>                                                        
                                                        <th>Area/Village Name</th>
                                                        <th>Area Cluster Name</th>
                                                        <th>City/Village</th>
                                                        <th>Created By</th>
                                                        <th>Notes</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.Arealist ?(
                                                            this.state.Arealist.map((option,index)=>{
                                                                return <tr key={index}>                                                                          
                                                                          <td>{option.id}</td>
                                                                          <td>{option.name}</td>
                                                                          <td>{option.area_cluster_name}</td>
                                                                          <td>{option.area_type_name}</td>                                                                          
                                                                          <td>{option.area_created_by}</td>
                                                                          <td>{option.notes}</td>                                                                          
                                                                         
                                                                        </tr>
                                                            })
                                                        ): null
                                                    }
                                                   
                                                    
                                                </tbody>
                                            </Table>
                                        </div>

                                    {/* paginnation */}
                                    <ReactPaginate
                                      previousLabel="&#x276E;"
                                      nextLabel="&#x276F;"
                                      breakLabel={'...'}
                                      breakClassName={'break-me'}
                                      pageCount={this.state.pageCount}
                                      initialPage={this.state.currentPage - 1}
                                      forcePage={this.state.currentPage - 1}
                                      marginPagesDisplayed={2}
                                      pageRangeDisplayed={5}
                                      onPageChange={this.handlePageClick}
                                      containerClassName={'pagination mt-4 float-right'}
                                      activeClassName={'active'}
                                      disableInitialCallback={true}
                                    /> 
        
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default SystemArea;
