import moment from 'moment';
import Swal from 'sweetalert2';
import { Route, Redirect } from "react-router-dom";
import {Loading,SuccessPopup, SwalClose, Head, Foot, ErrorPopup} from "../../helpers/globalfunctions";


// header
export function nonAuthHead(){
  return 'Develop & Design by <a target="_blank" href="https://nexisinfotech.com/">Nexis Infotech </a>';
};

// foot
export function nonAuthFoot(){  
  return 'Develop & Design by <a target="_blank" href="https://nexisinfotech.com/">Nexis Infotech </a>';
};

export function nonAuthVerifyFoot(){
   if(nonAuthFoot() != Foot()) {
      localStorage.clear();
      return (
        <Redirect to={{ pathname: "/admin-panel/login"}} />
      );
    }
}

export function nonAuthVerifyHead(){
   if(nonAuthHead() != Head()) {
      localStorage.clear();
      return (
        <Redirect to={{ pathname: "/admin-panel/login"}} />
      );
    }
}

