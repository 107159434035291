import React, { Component } from 'react';
import { Container, Row, Col, Table, Card, CardBody, Button, Form, FormGroup, Label, Input, FormText,
Pagination, PaginationItem, PaginationLink } from "reactstrap";
import MiniWidgets from "./MiniWidgets";
//import RevenueAnalytics from "./RevenueAnalytics";
import CommonApi from '../../apis/CommonApi';


//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
//import Stats from '../../apis/Stats';
let isMounted = false;
class DonerDashboard extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state={
            breadcrumbItems : [
                { title : "Dashboard", link : "#" },                
            ],
            all_beneficiary:'',
            city_beneficiary:'',
            village_beneficiary:'',
            total_deliverd:'',
            city_deliverd:'',
            village_deliverd:'',
            vendor_stock_report:[],
            total_beneficiary_verify:'',
            beneficiary_not_verify:'',
            total_kit_verify:'', 
            kit_not_verify:'',
            reports2 : [
                { icon : "ri-numbers-fill",width:'6', title : "Total Number of Kits", value : "19750", },
                
            ], 
            total_completed_area:'',                     
        }
    }

    componentDidMount(){
        this._isMounted = true;
        this.getDashboardReport()
        
    }

    getDashboardReport() {
        CommonApi.getDashboardReport().then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({all_beneficiary: response.data.data.all_beneficiary});
                    this.setState({city_beneficiary: response.data.data.city_beneficiary});
                    this.setState({village_beneficiary: response.data.data.village_beneficiary});
                    this.setState({total_deliverd: response.data.data.total_deliverd});
                    this.setState({city_deliverd: response.data.data.city_deliverd}); 
                    this.setState({village_deliverd: response.data.data.village_deliverd});
                    this.setState({vendor_stock_report: response.data.data.vendor_stock_report});
                    this.setState({total_beneficiary_verify: response.data.data.total_beneficiary_verify});
                    this.setState({beneficiary_not_verify: response.data.data.beneficiary_not_verify}); 
                    this.setState({total_kit_verify: response.data.data.total_kit_verify});
                    this.setState({kit_not_verify: response.data.data.kit_not_verify});
                    this.setState({total_completed_area: response.data.data.totalCompletedArea});
                    //this.setState({report3: [] })
                    /*this.setState({
                      report2: [ ...this.state.report2, 'new value'],
                    });*/

                    /*this.setState(prevState => ({
                        report2: {
                            ...prevState.report2,
                            [prevState.report2[index].value]: response.data.data.total_deliverd,
                        },
                    }));*/
                }
            }
        });
    }


    render() {        
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Dashboard" breadcrumbItems={this.state.breadcrumbItems} />

                        <Row>
                            <Col xl={6}>                                
                                <Row>
                                    <MiniWidgets reports={this.state.reports2} /> 

                                    <Col xl={6}><div class="card"><div class="card-body"><div class="media"><div class="overflow-hidden media-body"><h4 class="card-title font-size-14 mb-2">Distributed Kits</h4><h5 class="mb-0 font-size-16">{this.state.total_deliverd}</h5></div><div class="text-primary"><i class="ri-numbers-fill font-size-24"></i></div></div></div><div class=" py-3 card-body"><div class="text-truncate"></div></div></div></Col>
                                </Row>                                
                            </Col>
                            <Col xl={6}>
                                <Row>
                                    <Col xl={12}><div class="card"><div class="card-body"><div class="media"><div class="overflow-hidden media-body"><h4 class="card-title font-size-14 mb-2">Total number of area completed </h4><h5 class="mb-0 font-size-16">{this.state.total_completed_area}</h5></div><div class="text-primary"><i class="ri-numbers-fill font-size-24"></i></div></div></div><div class=" py-3 card-body"><div class="text-truncate"></div></div></div></Col>
                                </Row>
                            </Col>
                                                        
                        </Row>

                        <Row>
                            <Col lg={6}>
                                <Card>
                                    <CardBody>
                                        <div className="table-responsive mt-3">
                                            <center><h5>Beneficiary</h5></center>
                                            <Table className="mb-0" striped bordered hover>
                                                <thead className="bg-primary text-white">
                                                    <tr>
                                                        <th>Total Beneficiary</th>
                                                        <th>City Beneficiary</th>
                                                        <th>Village Beneficiary</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>                                                                                                                                                                                                                               
                                                      <td>{this.state.all_beneficiary}</td>              
                                                      <td>{this.state.city_beneficiary}</td>
                                                      <td>{this.state.village_beneficiary}</td>                                                                          
                                                   </tr>
                                                    
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={6}>
                                <Card>
                                    <CardBody>
                                        <div className="table-responsive mt-3">
                                            <center><h5>Kit Distribution</h5></center>
                                            <Table className="mb-0" striped bordered hover>
                                                <thead className="bg-primary text-white">
                                                    <tr>
                                                        <th>Total</th>
                                                        <th>City</th>
                                                        <th>Village</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>                                                                                                                                                                                                                               
                                                      <td>{this.state.total_deliverd}</td>              
                                                      <td>{this.state.city_deliverd}</td>
                                                      <td>{this.state.village_deliverd}</td>                                                                          
                                                   </tr>
                                                    
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>                        
                        

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <div className="table-responsive mt-3">
                                            <center><h5>Call Verification</h5></center>
                                            <Table className="mb-0" striped bordered hover>
                                                <thead className="bg-primary text-white">
                                                    <tr>
                                                        <th>Total Beneficiary Verify</th>
                                                        <th>Total Kit Verify</th>                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>                                                                                                                                                                                                                               
                                                      <td>{this.state.total_beneficiary_verify}</td>              
                                                      <td>{this.state.total_kit_verify}</td>                                                                                                                              
                                                   </tr>
                                                    
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> 

                        
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default DonerDashboard;
