import moment from 'moment';
import Swal from 'sweetalert2';
import { Route, Redirect } from "react-router-dom";
import {nonAuthFoot,nonAuthVerifyFoot,nonAuthHead,nonAuthVerifyHead} from "./AuthType/nonAuthFunction";

//Use this method To solve IOS date format problem  (in Safari and IOS default Browsers)
// convert datetime to time ago
export function DayDateFormat(date){
   return moment(new Date(date.replace(/-/g,"/"))).fromNow(true);
}

// date and time formate in dd-mm-yy
export function DateFormat(date){	
   return moment(date).format('DD-MM-YYYY');
}
// date and time formate in dd-mm-yy
export function DateTimeFormat(date){ 
   return moment(date).format('DD-MM-YYYY H:mm:ss');
}
// todat date
export function Today(){
  return moment();
}

// loading
export function Loading(){
	Swal.fire({
        title: 'Please Wait !',
        html: 'request sending...',
        allowOutsideClick: false,        
        didOpen: () => {
          Swal.showLoading()
        },
    });	
}

// swal close
export function SwalClose(){
  Swal.close();
}

export function SuccessPopup(message){
   Swal.fire(
      'Done',
      message,
      'success'
  );
}

export function ErrorMessage(message){
   Swal.fire(
      'Error!',
      message,
      'error'
  );
}

export function ErrorPopup(value){
  console.log(value.data);
  if(value.data.message){
      if(value.data.errors){                       
          var responseVal = value.data;            
          var errorString = '';            
            for ( var key in responseVal.errors ) {
              errorString += responseVal.errors[key] + "<br/>";                
          }
          Swal.fire("Error!", errorString, "error");
      }else{
          Swal.fire("Error!", value.data.message, "error");      
      }                  
  }else{
    Swal.fire("Error!", "Internal Server error", "error");  
  }
   
}

export function UpdatePopup(){
   Swal.fire(
      'Updated!',
      'Item has been updated successfully!.',
      'success'
  );
}

export function DeletedPopup(){
   Swal.fire(
      'Removed!',
      'Item has been removed successfully!.',
      'success'
  );
}

export const Checkbox = ({ label, isSelected, onCheckboxChange }) => (
    <div className="form-check">
      <label>
        <input
          type="checkbox"
          name={label}
          checked={isSelected}
          onChange={onCheckboxChange}
          className="form-check-input"
        />
        
      </label>
    </div>
);

export const Radiobox = ({ label, isSelected, onCheckboxChange }) => (
    <div className="form-check">
      <label>
        <input
          type="radio"
          name={label}
          checked={isSelected}
          onChange={onCheckboxChange}
          className="form-check-input"
        />
        
      </label>
    </div>
);

// header
export function Head(){
  return 'Develop & Design by <a target="_blank" href="https://nexisinfotech.com/">Nexis Infotech </a>';
};

// foot
export function Foot(){  
  return 'Develop & Design by <a target="_blank" href="https://nexisinfotech.com/">Nexis Infotech </a>';
};

export function VerifyFoot(){
   if(localStorage.getItem("user.system_footer")!= Foot() && nonAuthFoot() != Foot()) {
      localStorage.clear();
      return (
        <Redirect to={{ pathname: "/admin-panel/login"}} />
      );
    }
}

export function VerifyHead(){
   if(localStorage.getItem("user.system_header")!= Head() && nonAuthHead() != Head()) {
      localStorage.clear();
      return (
        <Redirect to={{ pathname: "/admin-panel/login"}} />
      );
    }
}

