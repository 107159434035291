import React, { Component } from "react";
import { Table, Row, Col, Card, CardBody, Container, Button, Form, FormGroup, Label, Input, FormText,
Pagination, PaginationItem, PaginationLink, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput } from "reactstrap";
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";
import {DateFormat,DateTimeFormat,Loading,SuccessPopup, SwalClose, ErrorPopup} from "../../helpers/globalfunctions";
import CommonApi from '../../apis/CommonApi';
import Swal from 'sweetalert2';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class KitManagement extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Kit", link : "#" },
                { title : "Kit Manage", link : "#" },
            ],
            listEmployee:[],
            pageCount: 1,
            currentPage: 1,
            per_page:100,
            search:'',
            isWaiting:false,
            modalIsOpen: false,
            stock_report:[],
        };

        this.handlePageClick = this.handlePageClick.bind(this);
        this.rowPerPageChange = this.rowPerPageChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.statusChange=this.statusChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.deleteConfirmModal=this.deleteConfirmModal.bind(this);
        this.handleDeleteKit=this.handleDeleteKit.bind(this);
    }

    componentDidMount() {
        this._isMounted = true
        this.getKitData()        
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //Get kit data
    getKitData() {
        CommonApi.getKitList(this.state.currentPage,this.state.per_page,this.state.search).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({listEmployee: response.data.data.result.data});
                    this.setState({pageCount: response.data.data.result.last_page});
                    this.setState({currentPage: response.data.data.result.current_page});
                    this.setState({per_page: response.data.data.result.per_page});
                    this.setState({stock_report: response.data.data.report});
                    
                }
            }
        });
    }
    

     // row per page    
    async rowPerPageChange(event) {        
        await this.setState({per_page: event.target.value});        
        this.getKitData()
    }

    // page change event
    async handlePageClick(data) {
        const page = data.selected >= 0 ? data.selected + 1 : 0;
        await Promise.resolve(this.setState(() => ({ currentPage: page })));        
        this.getKitData()
    }

    // on change search
    async handleSearch(searchText) {
        await this.setState({ search: searchText.target.value });
        await Promise.resolve(this.setState(() => ({ currentPage: 1 })));
        this.getKitData()
    }
    

    /* change active status */
    async statusChange(id){
        Loading()
        this.setState({ isWaiting: true });
        CommonApi.ChangeUserStatus(id).then(response => {
            SwalClose()
            if (response) {                
                this.getKitData();
                this.setState({ isWaiting: false });
            }
        });
    }    

    openModal = () => this.setState({ modalIsOpen: true });    
    closeModal = () => this.setState({ modalIsOpen: false });

    // Add Kit API
    handleSubmit(event) {
        event.preventDefault();
        Loading()
        const data = new FormData(event.target);
        CommonApi.addKit(data).then(response => {
            SwalClose()
            
            if (response.data) {
                this.setState({modalIsOpen:false});
                SuccessPopup(response.data.message)
                this.getKitData();
            }
        }).catch((error) => {
            SwalClose()
            if (error.response) {
                ErrorPopup(error.response)
            }

        });      
    }
    /* Delete Confirm Modal */
    async deleteConfirmModal(id){
        await this.setState({deleteModalIsOpen:true});
        await this.setState({delete_id:id});
    }
    /* Delete function */
    handleDeleteKit(event){
        event.preventDefault();
        Loading()        

        CommonApi.deleteKit(this.state.delete_id).then(response => {
            SwalClose()
            
            if (response.data.status==1) {
                this.setState({deleteModalIsOpen:false});
                SuccessPopup(response.data.message)
                this.getKitData();
            }
        }).catch((error) => {
            SwalClose()
            if (error.response) {
                ErrorPopup(error.response)
            }

        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Kit Manage" breadcrumbItems={this.state.breadcrumbItems} />
                        

                        <Row>
                            <Col lg={12} className="mb-2">
                                <div>
                                  <Button color="primary" onClick={this.openModal}>Add Kit</Button>
                                  <Modal isOpen={this.state.modalIsOpen}>
                                    <ModalHeader>Add Kit</ModalHeader>
                                    <Form onSubmit={this.handleSubmit}>
                                    <ModalBody>

                                            <FormGroup>
                                                <Label for="total_kit_qty">Total kit qunatity*</Label>
                                                <Input type="text" name="total_kit_qty" id="total_kit_qty" placeholder="Total number of kit" />
                                            </FormGroup>
                                            

                                            {/*<FormGroup>
                                                <Label for="user_role">Role*</Label>
                                                <Input type="select" name="user_role" id="user_role">
                                                  <option value="">--Select Role--</option>                                                  
                                                  {
                                                        this.state.rolesList ?(
                                                            this.state.rolesList.map((option,index)=>{
                                                                return <option key={index} value={option.id}>{option.name}</option>
                                                            })
                                                        ): null
                                                    }
                                                </Input>
                                            </FormGroup>
                                            */}                                            
                                        
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="secondary" onClick={this.closeModal}>Cancel</Button>{' '}
                                        <Button type="submit" color="primary">Save</Button>                           
                                    </ModalFooter>
                                    </Form>
                                  </Modal>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            {/* deleteModal */ }
                            <Modal isOpen={this.state.deleteModalIsOpen}>
                                <ModalHeader>Delete Kit stock? </ModalHeader>
                                <Form onSubmit={this.handleDeleteKit}>
                                <ModalBody>
                                    <p>Are you sure you want to delete Stock?</p>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={()=>this.setState({deleteModalIsOpen:false})}>Cancel</Button>{' '}
                                    <Button type="submit" color="primary">Delete</Button>                           
                                </ModalFooter>
                                </Form>
                            </Modal>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <center><h5>Kit Stock Report</h5></center>
                                        <div className="table-responsive">
                                            <Table className="mb-0" striped bordered hover>
                                                <thead className="bg-primary text-white">
                                                    <tr>                                                        
                                                        <th>Name</th>
                                                        <th>Total Stock</th>                                                        
                                                        <th>Send Stock</th>                                                        
                                                        <th>Return Back</th>                                                        
                                                        <th>Available Stock</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                   {
                                                        this.state.stock_report ?(
                                                            this.state.stock_report.map((option,index)=>{
                                                                return <tr key={index}>                                                                          
                                                                          <td>{option.vendor_name}</td>
                                                                          <td>{option.total_kit_stock}</td>
                                                                          <td>{option.on_way_stock}</td> 
                                                                          <td>{option.return_back}</td>                                                                          
                                                                          <td>{option.available_stock}</td>
                                                                       </tr>
                                                            })
                                                        ): null
                                                    }
                                                    
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>                                       

                                        <Row>
                                            <Col lg={2}>
                                                <Row>
                                                    <Col lg={3}>
                                                        <FormGroup className="mt-2">                                                    
                                                            <Label for="selectRows">Show</Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <FormGroup>                                                    
                                                            <Input type="select" name="selectRows" onChange={this.rowPerPageChange} id="selectRows" value={this.state.per_page}
                                                             >
                                                              <option value='10'>10</option>
                                                              <option value='20'>20</option>
                                                              <option value='50'>50</option>
                                                              <option value='100'>100</option>
                                                              <option value='500'>500</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>                                                 
                                            </Col>
                                            <Col lg={7}>
                                                {/*<Button>Print</Button>*/}
                                            </Col>
                                            <Col lg={3}>
                                                 <Row>
                                                    <Col lg={3}>
                                                        <FormGroup className="mt-2">                                    
                                                            <Label for="search">search</Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={9}>
                                                        <Form>
                                                          <FormGroup>                                                            
                                                            <Input type="text" onKeyUp={this.handleSearch} name="search" id="search" placeholder="" />
                                                          </FormGroup>
                                                        </Form>
                                                    </Col>
                                                 </Row>                                                
                                            </Col>

                                        </Row>


        
                                        <div className="table-responsive">
                                            <Table className="mb-0" striped bordered hover>
                                                <thead className="bg-primary text-white">
                                                    <tr>                                                        
                                                        <th>Name</th>
                                                        <th>Total Stock</th>
                                                        <th>New or Return</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.listEmployee ?(
                                                            this.state.listEmployee.map((option,index)=>{
                                                                return <tr key={index}>                                                                          
                                                                          <td>{option.vendor_name}</td>
                                                                          <td>{option.total_kit_qty}</td>
                                                                          <td>{(option.is_return=='Yes')?'Return':'New'}</td>
                                                                          <td>
                                                                             <Button color="danger" className="btn-sm" onClick={()=>this.deleteConfirmModal(option.id)}><i className="ri-delete-bin-2-line"></i></Button>                                                        
                                                                          </td>
                                                                       </tr>
                                                            })
                                                        ): null
                                                    }
                                                   
                                                    
                                                </tbody>
                                            </Table>
                                        </div>

                                    {/* paginnation */}
                                    <ReactPaginate
                                      previousLabel="&#x276E;"
                                      nextLabel="&#x276F;"
                                      breakLabel={'...'}
                                      breakClassName={'break-me'}
                                      pageCount={this.state.pageCount}
                                      initialPage={this.state.currentPage - 1}
                                      forcePage={this.state.currentPage - 1}
                                      marginPagesDisplayed={2}
                                      pageRangeDisplayed={5}
                                      onPageChange={this.handlePageClick}
                                      containerClassName={'pagination mt-4 float-right'}
                                      activeClassName={'active'}
                                      disableInitialCallback={true}
                                    /> 
        
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default KitManagement;