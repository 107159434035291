import React, { Component } from 'react';

import { Row, Col, Input, Button, Alert, Container, Label, FormGroup } from "reactstrap";
import axios from "axios";
import {nonAuthFoot,nonAuthVerifyFoot} from "../../helpers/AuthType/nonAuthFunction";

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {Loading,SuccessPopup, SwalClose, ErrorPopup} from "../../helpers/globalfunctions";
// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';
// actions
import { checkLogin, apiError } from '../../store/actions';

import Auth from '../../apis/Auth';


class Login extends Component {

    constructor(props) {
        super(props);
        
        //this.state = {  email : "admin@themesdesign.in", password : "123456" }
        this.state = {
            // email: "",
            // password: "",
            error_message: null,
            errors: null
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(event, values) {
        //console.log(values)

        this.setState({
            error_message: null,
            errors: null
        });
  
        if(values.email == "" || values.password == "") {
            this.setState({
                error_message: "Please enter login credentials"
            });
  
            return false;
        }

        Loading()
        await Auth.login({email: values.email, password: values.password}, (response) => {
            SwalClose()
            if(response.data.data.access_token != "") {
                for (var i in response.data.data) {
                    localStorage.setItem("user." + i, response.data.data[i]);
                }                    
                    if(localStorage.getItem("user.user_scopes")=='donor'){                        
                        setTimeout(() => {
                            this.props.history.push("/admin-panel/doner-dashboard");
                        }, 500);                        
                    }else if((localStorage.getItem("user.user_scopes")=='supervisor')){                        
                        setTimeout(() => {
                            this.props.history.push("/admin-panel/supervisore-dashboard");
                        }, 500);
                    }
                    else if((localStorage.getItem("user.user_scopes")=='vendor')){                        
                        setTimeout(() => {
                            this.props.history.push("/admin-panel/vendor-dashboard");
                        }, 500);
                    }
                    else if((localStorage.getItem("user.user_scopes")=='verifier')){                        
                        setTimeout(() => {
                            this.props.history.push("/admin-panel/verifier-dashboard");
                        }, 500);
                    }
                    else if((localStorage.getItem("user.user_scopes")=='admin')){                        
                        setTimeout(() => {
                            this.props.history.push("/admin-panel/dashboard");
                        }, 500);
                        
                    }
            } else {
                localStorage.clear();  
                this.setState({
                    error_message: "Unauthorized"
                });
            }
        }, (err) => {
            SwalClose()
            console.log({'erre':err})
            this.setState({
                error_message: err.response.data.error,
                errors: err.response.data.error
            });
        });

        // this.props.checkLogin(values, this.props.history);
    }

    componentDidMount(){
        this.props.apiError("");
        document.body.classList.add("auth-body-bg");
        nonAuthVerifyFoot();
    }

    componentWillUnmount(){
        document.body.classList.remove("auth-body-bg");
    }

    render() {

        return (
            <React.Fragment>
                <div className="home-btn d-none d-sm-block">
                    <Link to="/"><i className="mdi mdi-home-variant h2 text-white"></i></Link>
                </div>
                
                <div>
            <Container fluid className="p-0">
                <Row className="no-gutters">
                    <Col lg={4}>
                        <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                            <div className="w-100">
                                <Row className="justify-content-center">
                                    <Col lg={9}>
                                        <div>
                                            <div className="text-center">
                                                <div>
                                                    {/* <Link to="/" className="logo"><img src={logodark} height="20" alt="logo"/></Link> */}                                                    
                                                </div>                                                
                                                <h5 className="login-header-title">Ramadan Food Packets Distribution {new Date().getFullYear()}  (Bharuch District)</h5>
                                                <h6 className="login-sub-header-title">Funded by ISSA Foundation UK & Manage by MMMCT Bharuch</h6>                                              
    
                                                {/* <h4 className="font-size-18 mt-4">Welcome Back !</h4>
                                                <p className="text-muted">Sign in to continue to Nazox.</p> */}
                                            </div>

                                            <div className="p-2 mt-5">
                                                <h4 className="mb-4 text-center">Login</h4>
                                                <AvForm className="form-horizontal mb-4" onValidSubmit={this.handleSubmit} >
                    
                                                    <FormGroup className="auth-form-group-custom mb-4">
                                                        <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                        <Label htmlFor="email">Username</Label>
                                                        <AvField name="email" value={this.state.email} type="text" className="form-control" id="email" validate={{email: true, required: true}} placeholder="Enter email"/>
                                                    </FormGroup>
                            
                                                    <FormGroup className="auth-form-group-custom mb-4">
                                                        <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                        <Label htmlFor="userpassword">Password</Label>
                                                        <AvField name="password" value={this.state.password} type="password" className="form-control" id="userpassword" placeholder="Enter password"/>
                                                    </FormGroup>
                            
                                                    <div className="custom-control custom-checkbox">
                                                        <Input type="checkbox" className="custom-control-input" id="customControlInline"/>
                                                        <Label className="custom-control-label" htmlFor="customControlInline">Remember me</Label>
                                                    </div>

                                                    <div className="mt-4 text-center">
                                                        <Button color="primary" className="w-md waves-effect waves-light" type="submit">Log In</Button>
                                                    </div>

                                                    {/* <div className="mt-4 text-center">
                                                        <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Forgot your password?</Link>
                                                    </div> */}
                                                </AvForm>
                                            {this.state.error_message ? <Alert color="danger">{this.state.error_message}</Alert> : <div style={{height:47.73}} ></div> }

                                            </div>

                                             <div className="mt-5 text-center">                                                
                                                <p>{new Date().getFullYear()} © <span dangerouslySetInnerHTML={{__html: nonAuthFoot()}} /></p>
                                            </div>
                                        </div>

                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col lg={8}>
                        <div className="authentication-bg" >
                            <div className="bg-overlay"></div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { loginError } = state.Login;
    return { loginError };
}

export default withRouter(connect(mapStatetoProps, { checkLogin, apiError })(Login));