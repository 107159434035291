import React, { Component } from "react";
import { Table, Row, Col, Card, CardBody, Container, Button, Form, FormGroup, Label, Input, FormText,
Pagination, PaginationItem, PaginationLink, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput } from "reactstrap";
import ReactDOM from 'react-dom';
import User from '../../apis/User';
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";
import {DateFormat,DateTimeFormat,Loading,SuccessPopup, SwalClose, ErrorPopup,ErrorMessage,Checkbox} from "../../helpers/globalfunctions";
import CommonApi from '../../apis/CommonApi';
import Swal from 'sweetalert2';
import Select from "react-select";
import Compressor from 'compressorjs';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class AreaCardReport extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Report", link : "#" },
                { title : "Area card", link : "#" },
            ],
            listArea:[],
            pageCount: 1,
            currentPage: 1,
            per_page:100,
            search:'',
            isWaiting:false,            
            selectedMulti: '',
            selectedMultiAreaCluster:'',
            selectedMultiArea: '',
            area_name: '',
            values:[],
            area_id:'',
            area_type:'',
            area_type_id:'',
            cardStatus:[],
            Checked: false,
            city_village:'',
            area_cluster:'',
            area_village:'',
            vendor:[],
            total_generated_card:'',
            total_not_generated_card:'',
        };

        this.handlePageClick = this.handlePageClick.bind(this);
        this.rowPerPageChange = this.rowPerPageChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleMulti = this.handleMulti.bind(this);
        this.handleMultiAreaCluster=this.handleMultiAreaCluster.bind(this);              
        this.GetAreaTypeList= this.GetAreaTypeList.bind(this);
        this.areaClusterList=this.areaClusterList.bind(this);
        this.getAreaCardReport=this.getAreaCardReport.bind(this);
        this.searchRecord=this.searchRecord.bind(this);
        
    }

    componentDidMount() {
        this._isMounted = true
        this.getTotalCardCount()       
        this.GetAreaTypeList()        
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getTotalCardCount(){
        CommonApi.getTotalCardCount().then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({total_generated_card: response.data.data.total_generated_card});
                    this.setState({total_not_generated_card: response.data.data.total_not_generated_card});
                }                    
            }
        });
    }

    //Get Use data
    getAreaCardReport(){
        Loading()
        CommonApi.getAreaCardReport(this.state.currentPage,this.state.per_page,this.state.search,this.state.city_village,this.state.area_cluster).then(response => {
            if (response) {
                SwalClose()
                if(this._isMounted) {                    
                    this.setState({listArea: response.data.data.result.result.data});                    
                    const OPTIONS  = response.data.data.result.map(row => row.id);
                    //console.log(OPTIONS);
                    this.setState({checkboxes: OPTIONS.reduce(
                      (options, option) => ({
                        ...options,
                        [option]: false
                      }),
                      {}
                    )})                    
                }                
            }
        }).catch((error) => {
            SwalClose()
            if (error.response) {
                ErrorPopup(error.response)
            }

        });
    }

    // Get Area type list
    GetAreaTypeList(){
        CommonApi.GetAreaTypeList().then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({area_type_id: [{label: "Select",options:response.data.data}]});
                }                    
            }
        });
    }

    // Get area cluster list 
    areaClusterList(data){
        CommonApi.getAreaClusterList(data).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({area_cluster_id: [{label: "Select",options:response.data.data}]});
                }                    
            }
        });
    }

    /* search button event */
    searchRecord(event){        
        this.getAreaCardReport();
    }

    //get vendor list for generate route
    getvendorList(){
        CommonApi.vendorList().then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({vendor: response.data.data});
                }                    
            }
        });
    }

     // row per page    
    async rowPerPageChange(event) {        
        await this.setState({per_page: event.target.value});
    }

    // page change event
    async handlePageClick(data) {
        const page = data.selected >= 0 ? data.selected + 1 : 0;
        await Promise.resolve(this.setState(() => ({ currentPage: page })));        
        this.getAreaCardReport()
    }

    // on change search
    async handleSearch(searchText) {
        await this.setState({ search: searchText.target.value });
        await Promise.resolve(this.setState(() => ({ currentPage: 1 })));
        this.getAreaCardReport()
    }

    /* select area type */
    handleMulti = selectedMulti => {
        this.setState({ selectedMulti });
        this.setState({ city_village: selectedMulti.value });
        this.setState({selectedMultiAreaCluster:[]})
        this.areaClusterList(selectedMulti.value);
    };

    /* Select area cluster */
    handleMultiAreaCluster = selectedMultiAreaCluster => {
        this.setState({ selectedMultiAreaCluster });
        this.setState({ area_cluster: selectedMultiAreaCluster.value });                
    };
    
    
    render() {

        const { selectedMulti } = this.state;
        const { selectedMultiArea } = this.state;
        const { selectedMultiAreaCluster } =this.state;        

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Area Card Report" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <div className="table-responsive mt-3">
                                            <Table className="mb-0" striped bordered hover>
                                                <thead className="bg-primary text-white">
                                                    <tr>
                                                        <th>Total Generated Card</th>
                                                        <th>Total Not Generated</th>                                                       
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>                                                                                                                                                                                                                               
                                                      <td>{this.state.total_generated_card}</td>              
                                                      <td>{this.state.total_not_generated_card}</td>                                                                          
                                                   </tr>
                                                    
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>                                        

                                        <Row>
                                            <Col lg={3}>
                                                <FormGroup className="select2-container">
                                                    <Label className="control-label">Select City/Village* (Area Type)</Label>
                                                    <Select name="area_type"
                                                        value={selectedMulti}
                                                        isMulti={false}
                                                        onChange={this.handleMulti}
                                                        options={this.state.area_type_id}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg={3}>

                                                <FormGroup className="select2-container">
                                                    <Label className="control-label">Select Area Cluster*</Label>
                                                    <Select name="area_cluster"
                                                        value={selectedMultiAreaCluster}
                                                        isMulti={false}
                                                        onChange={this.handleMultiAreaCluster}
                                                        options={this.state.area_cluster_id}
                                                        classNamePrefix="select2-selection"
                                                    />
                                                </FormGroup>
                                            </Col>                                            
                                            
                                            <Col lg={1} sm={6}>
                                                <Button className="mt-4" color="primary" onClick={this.searchRecord}>Search</Button>
                                            </Col>

                                            
                                        </Row>

        
                                        <div className="table-responsive mt-3">
                                            <Table className="mb-0" striped bordered hover>
                                                <thead className="bg-primary text-white">
                                                    <tr>
                                                                                                                
                                                        <th>Area No</th>
                                                        <th>Area Name</th>
                                                        <th>Generated Card</th>
                                                        <th>Not Generated</th>                                                       
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.listArea ?(
                                                            this.state.listArea.map((option,index)=>{
                                                                return <tr key={index}>
                                                                                                                                                      
                                                                          <td>{option.id}</td>                                                                          
                                                                          <td>{option.name}</td>                                                                          
                                                                          <td>{option.generated_card}</td>              
                                                                          <td>{option.not_generated_card}</td>                                                                          
                                                                       </tr>
                                                            })
                                                        ): null
                                                    }                                                   
                                                   
                                                    
                                                </tbody>
                                            </Table>
                                        </div>

                                    {/* paginnation */}
                                    <ReactPaginate
                                      previousLabel="&#x276E;"
                                      nextLabel="&#x276F;"
                                      breakLabel={'...'}
                                      breakClassName={'break-me'}
                                      pageCount={this.state.pageCount}
                                      initialPage={this.state.currentPage - 1}
                                      forcePage={this.state.currentPage - 1}
                                      marginPagesDisplayed={2}
                                      pageRangeDisplayed={5}
                                      onPageChange={this.handlePageClick}
                                      containerClassName={'pagination mt-4 float-right'}
                                      activeClassName={'active'}
                                      disableInitialCallback={true}
                                    /> 
        
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default AreaCardReport;