import React, { Component } from 'react';
import { Container, Row, Col, Table, Card, CardBody, Button, Form, FormGroup, Label, Input, FormText,
Pagination, PaginationItem, PaginationLink } from "reactstrap";
//import MiniWidgets from "./MiniWidgets";
//import RevenueAnalytics from "./RevenueAnalytics";
import CommonApi from '../../apis/CommonApi';
import {DateFormat,DateTimeFormat,Loading,SuccessPopup, SwalClose, ErrorPopup, ErrorMessage} from "../../helpers/globalfunctions";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
//import Stats from '../../apis/Stats';
let isMounted = false;
class SupervisoreDashboard extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state={
            breadcrumbItems : [
                { title : "Dashboard", link : "#" },                
            ],            
            total_deliverd:'',
            city_deliverd:'',
            village_deliverd:'', 
            my_delivered_Kit:'',
            my_remaining_kit:'',
            area_delivered_Kit:[],
        }
    }

    componentDidMount(){
        this._isMounted = true;
        this.getDashboardReport()
        
    }

    getDashboardReport() {
        CommonApi.getDashboardReport().then(response => {
            if (response) {
                if(this._isMounted) {                    
                    this.setState({area_delivered_Kit: response.data.data.area_delivered_Kit});                    
                    this.setState({my_delivered_Kit: response.data.data.my_delivered_Kit});
                    this.setState({my_remaining_kit: response.data.data.my_remaining_kit});                                        
                    
                }
            }
        });
    }


    render() {        
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Dashboard" breadcrumbItems={this.state.breadcrumbItems} />                        

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <div className="table-responsive mt-3">
                                            <center><h5>My Distribution</h5></center>
                                            <Table className="mb-0" striped bordered hover>
                                                <thead className="bg-primary text-white">
                                                    <tr>
                                                        <th>Total Delivered</th>
                                                        <th>Total Remaining</th>                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>                                                                                                                                                                                                                               
                                                      <td>{this.state.my_delivered_Kit}</td>              
                                                      <td>{this.state.my_remaining_kit}</td>                                                                                                                              
                                                   </tr>
                                                    
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>


                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <div className="table-responsive mt-3">
                                            <center><h5>Area based kit Distribution</h5></center>
                                            <Table className="mb-0" striped bordered hover>
                                                <thead className="bg-primary text-white">
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Area Name</th>
                                                        <th>Cluster Name</th>
                                                        <th>Number Of Kit Distribution</th>                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    this.state.area_delivered_Kit?(
                                                        this.state.area_delivered_Kit.map((option, index)=>{
                                                            return <tr key={index}>                                                                                                                                                                                                                               
                                                                      <td>{DateFormat(option.date)}</td>              
                                                                      <td>{option.name}</td>
                                                                      <td>{option.area_cluster_name}</td>
                                                                      <td>{option.kit_dist_count}</td>
                                                                   </tr>
                                                        })
                                                    ):null
                                                }                                                   
                                                    
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                                                                       

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default SupervisoreDashboard;
