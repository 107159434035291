import React, { Component } from "react";

import { Row, Col, Card, CardBody, FormGroup, Button, Label, Input ,Container, CustomInput, InputGroup, Form, InputGroupAddon } from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import CommonApi from '../../apis/CommonApi';
import Swal from 'sweetalert2';
import {Loading,SwalClose,ErrorMessage,ErrorPopup,SuccessPopup} from "../../helpers/globalfunctions";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class KitCheckOut extends Component {
    _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems : [
        { title : "Kit", link : "#" },
        { title : "Check Out", link : "#" },
      ],
      error_message: null,
      errors: null,
      vendor:[],
      vehicle:[],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVehicleKMOutSubmit = this.handleVehicleKMOutSubmit.bind(this);
  }

    componentDidMount() {
        this._isMounted = true
        this.getvendorList()
        this.getVehicleList()
    }
  

  //get vendor list for generate route
    getvendorList(){
        CommonApi.vendorList().then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({vendor: response.data.data});
                }                    
            }
        });
    }

    //get Vehicle list
    getVehicleList(){
        CommonApi.getVehicleList().then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({vehicle: response.data.data});
                }                    
            }
        });
    }

  handleSubmit(e){
    e.preventDefault();
    Loading()
    const data = new FormData(event.target);    
    CommonApi.returnKit(data).then(response => {
        SwalClose()
        if (response.data) {                
            SuccessPopup(response.data.message)            
        }
    }).catch((error) => {
        SwalClose()
        if (error.response) {                
            ErrorPopup(error.response)
        }

    });
  }

  handleVehicleKMOutSubmit(e){
    e.preventDefault();
    Loading()
    const data = new FormData(event.target);    
    CommonApi.vehicleOutKM(data).then(response => {
        SwalClose()
        if (response.data) {                
            SuccessPopup(response.data.message)            
        }
    }).catch((error) => {
        SwalClose()
        if (error.response) {                
            ErrorPopup(error.response)
        }

    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>          
          

          <Breadcrumbs title="Kit Check Out" breadcrumbItems={this.state.breadcrumbItems} />
            <Row>
                <Col xl="12">
                    <Card>
                        <CardBody>                            
                            <Form className="needs-validation" method="post" id="tooltipForm" onSubmit={this.handleSubmit}>
                                <Row>

                                    <Col md="4">
                                        <FormGroup>
                                            <Label for="from_vendor">Select From Vendor*</Label>
                                            <small>(Vendor from whom you get kits)</small>
                                            <Input type="select" name="from_vendor" id="from_vendor">
                                                <option value="">Select</option>
                                                {
                                                    this.state.vendor ? (this.state.vendor.map((value,key)=>{
                                                        return <option key={key} value={value.id}>{value.first_name}</option>
                                                    })

                                                    ):null
                                                }

                                            </Input>

                                        </FormGroup>
                                    </Col>

                                    <Col md="4">
                                        <FormGroup>
                                            <Label for="store_vendor">Select Store Vendor*</Label>
                                            <small>(Vendor from whom you return kits)</small>
                                            <Input type="select" name="store_vendor" id="store_vendor">
                                                <option value="">Select</option>
                                                {
                                                    this.state.vendor ? (this.state.vendor.map((value,key)=>{
                                                        return <option key={key} value={value.id}>{value.first_name}</option>
                                                    })

                                                    ):null
                                                }

                                            </Input>

                                        </FormGroup>
                                    </Col>                                    
                                    <Col md="3">
                                        <FormGroup>
                                            <Label for="return_qty">Total Return kit qunatity*</Label>
                                            <Input type="text" name="return_qty" id="return_qty" placeholder="Total number of kit return" />
                                        </FormGroup>
                                    </Col>
                                    <Col md="1" className="mt-4">
                                        <Button className="align-middle" color="primary" type="submit">Submit</Button>
                                    </Col>
                                    
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col> 
            </Row>


            <Row>                
                <Col xl="12">
                    <h5>Vehicle KM Out Checkout</h5> 
                    <Card>
                        <CardBody>                                                       
                            <Form className="needs-validation" method="post" id="tooltipForm" onSubmit={this.handleVehicleKMOutSubmit}>
                                <Row>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label for="vehicle_number">Select Vehicle*</Label>                                            
                                            <Input type="select" name="vehicle_number" id="vehicle_number">
                                                <option value="">Select</option>
                                                {
                                                    this.state.vehicle ? (this.state.vehicle.map((value,key)=>{
                                                        return <option key={key} value={value.vehicle_number}>{value.vehicle_number}</option>
                                                    })

                                                    ):null
                                                }

                                            </Input>

                                        </FormGroup>
                                    </Col>                                    
                                    <Col md="3">
                                        <FormGroup>
                                            <Label for="vehicle_out_km">Vehicle KM Out*</Label>
                                            <Input type="text" name="vehicle_out_km" id="vehicle_out_km" placeholder="Vehicle KM Out number" />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3" className="mt-4">
                                        <Button className="align-middle" color="primary" type="submit">Submit</Button>
                                    </Col>
                                    
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Col> 
            </Row>

          </Container>
          </div>
      </React.Fragment>
    );
  }
}

export default KitCheckOut;
