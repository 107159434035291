import Api from "./api";
 
const Auth = {
  login: (data, successCb, failCb) => {
      Api.post('/admin/login', data).then(response => {
 
          successCb(response);
 
      }).catch(err => {
 
          failCb(err);
      });
  },
  logout: () => {
      return Api.get('/admin/logout', {headers: {Authorization: 'Bearer ' + localStorage.getItem("user.access_token")}});
  },
  checkAuth: (successCb, failCb) => {
      Api.get('/admin/check-auth', {headers: {Authorization: 'Bearer ' + localStorage.getItem("user.access_token")}})
          .then(response => {
            successCb(response);
          }).catch(err => {
            failCb(err);
      });
  }  
};
 
export default Auth;