import React, { Component } from 'react';
import { Container, Row, Col, Table, Card, CardBody, Button, Form, FormGroup, Label, Input, FormText,
Pagination, PaginationItem, PaginationLink } from "reactstrap";
import MiniWidgets from "./MiniWidgets";
import RevenueAnalytics from "./RevenueAnalytics";


//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Stats from '../../apis/Stats';
let isMounted = false;
class UserDashboard extends Component {
    constructor(props) {
        super(props);
        this.state={
            series: [],
            series1: [{
                name: '2020',
                type: 'column',
                data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
            }, {
                name: '2019',
                type: 'line',
                data: [23, 32, 27, 38, 27, 32, 27, 38, 22, 31, 21, 16]
            }],
            labels: ['Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec','as','asd'],

            breadcrumbItems : [
                // { title : "Nazox", link : "#" },
                { title : "User Dashboard", link : "#" },
            ],

            reports1 : [
                { icon : "ri-group-fill", title : "Total Users", value : "11", },
                { icon : "ri-community-fill", title : "Total Role", value : "2", },
                { icon : "ri-macbook-fill", title : "Total Zakat Peoples", value : "108", },
                { icon : "ri-task-fill", title : "Total Area", value : "20", },
            ],
            reports2 : [
                { icon : "ri-numbers-fill",width:'6', title : "Available Kits", value : "1500", },
                { icon : "ri-numbers-fill",width:'6', title : "Distribute Kits", value : "500", },
            ],
            reports3 : [
                { icon : "ri-book-mark-fill", width:'6', title : "Total Kits", value : "2000",  },
                
                { icon : "ri-numbers-fill", width:'6', title : "Status", value : "",  data : [
                    {title:'Complated',value:'500'},
                    {title:'Pending',value:'100'},
                ] },
            ]
        }
    }

    componentDidMount(){
        isMounted = true;
        /*Stats.info().then(res => {
            console.log(res.data.series1[0])
            // if(res.data.status == 200){
                res.data.series1.length

                this.setState({
                    reports1 : res.data.reports1,
                    reports2 : res.data.reports2,
                    reports3 : res.data.reports3,
                    series :   res.data.series2,
                    labels :  res.data.series2[0]['period']
                })
            // }
        }).catch(err => {

        });*/
    }


    render() {        
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="User Dashboard" breadcrumbItems={this.state.breadcrumbItems} />

                        <Row>
                            <Col xl={12}>
                                <Row>
                                    <MiniWidgets reports={this.state.reports1} />                        
                                </Row>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl={6}>
                                {/* <MiniWidgets reports={this.state.reports2} /> */}
                                {this.state.series.length > 0 ? 
                                <RevenueAnalytics labels={this.state.labels} series={this.state.series} />
                                : null}
                                <Row>
                                    <MiniWidgets reports={this.state.reports2} />                        
                                </Row>                     
                            </Col>
                            <Col xl={6}>
                                <Row>
                                    <MiniWidgets reports={this.state.reports3} />                        
                                </Row>
                            </Col>
                        </Row>                                               

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default UserDashboard;
