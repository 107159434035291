import Api from "./api";
 
const User = {   
    employeeList:(currentPage,per_page,search)=>{
        return Api.get('/admin/user-fetch-data?page='+currentPage+'&per_page='+per_page+'&search='+search);
    },
    userSearch:(currentPage,per_page,search)=>{
        return Api.get('/admin/user-search?page='+currentPage+'&per_page='+per_page+'&search='+search);
    },
    departmentList:(currentPage,per_page,search)=>{
        return Api.get('/admin/departments-list?page='+currentPage+'&per_page='+per_page+'&search='+search);
    },
    roleList:(currentPage,per_page,search)=>{
        return Api.get('/admin/system-role-data?page='+currentPage+'&per_page='+per_page+'&search='+search);
    },
    

};

export default User;