import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";
import Profile from "../pages/Authentication/profile";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import UserDashboard from "../pages/Dashboard/UserDashboard";
import SupervisoreDashboard from "../pages/Dashboard/SupervisoreDashboard";
import VendorDashboard from "../pages/Dashboard/VendorDashboard";
import VerifierDashboard from "../pages/Dashboard/VerifierDashboard";
import DonerDashboard from "../pages/Dashboard/DonerDashboard";


/* Admin pages */
import USERS from "../pages/Users/user-index";
import UserCardGenerate from "../pages/Users/UserCardGenerate";
import RoleList from "../pages/Users/RoleList";
import SystemArea from "../pages/Users/SystemArea";
import Beneficiary from "../pages/ZakatPeople/Beneficiary";
import BeneficiaryCardGenerate from "../pages/ZakatPeople/BeneficiaryCardGenerate";
import RouteGenerate from "../pages/RouteArea/RouteGenerate";
import RouteDisplay from "../pages/RouteArea/RouteDisplay";
import KitManagement from "../pages/kit/KitManagement";
import KitDistribution from "../pages/kit/KitDistribution";
import KitTracking from "../pages/kit/KitTracking";
import QRscanner from "../pages/kit/QRscanner";
import QRNumberSubmit from "../pages/kit/QRNumberSubmit";
import BeneficiaryVerify from "../pages/verifiyer/BeneficiaryVerify";
import DeliveredKit from "../pages/verifiyer/DeliveredKit";
import KitCheckOut from "../pages/kit/KitCheckOut";


/* Report */
import BeneficiaryReport from "../pages/Reports/BeneficiaryReport";
import KitStockReport from "../pages/Reports/KitStockReport";
import AreaCardReport from "../pages/Reports/AreaCardReport";
import BeneficiaryVerifyReport from "../pages/Reports/BeneficiaryVerifyReport";
import KitVerifyReport from "../pages/Reports/KitVerifyReport";


const isRole=localStorage.getItem("user.user_scopes");

/*if(isRole=='user'){	
	const home="/admin-panel/user-dashboard";
}else if(isRole!='user'){
	const home="/admin-panel/dashboard";
}*/

const authProtectedRoutes = [{ path: "/admin-panel/dashboard", component: Dashboard },	
	{ path: "/admin-panel/users", component: USERS },
	{ path: "/admin-panel/role", component: RoleList },
	{ path: "/admin-panel/system-area", component: SystemArea },
	{ path: "/admin-panel/beneficiary", component: Beneficiary },
	{ path: "/admin-panel/beneficiary-card-generate", component: BeneficiaryCardGenerate },
	{ path: "/admin-panel/generate-route", component: RouteGenerate },
	{ path: "/admin-panel/display-route", component: RouteDisplay },
	{ path: "/admin-panel/kit-management", component: KitManagement },
	{ path: "/admin-panel/kit-distribution", component: KitDistribution },
	{ path: "/admin-panel/kit-tracking", component: KitTracking },
	{ path: "/admin-panel/beneficiary-card-scan", component: QRscanner },
	{ path: "/admin-panel/beneficiary-qr-number", component: QRNumberSubmit },	
	{ path: "/admin-panel/user-card-generate", component: UserCardGenerate },
	{ path: "/admin-panel/beneficiary-verify", component: BeneficiaryVerify },
	{ path: "/admin-panel/beneficiary-kit-verify", component: DeliveredKit },
	{ path: "/admin-panel/supervisore-check-out", component: KitCheckOut },
	{ path: "/admin-panel/card-report", component: AreaCardReport },
	

	/* Dashboard */
	{ path: "/admin-panel/supervisore-dashboard", component: SupervisoreDashboard },
	{ path: "/admin-panel/vendor-dashboard", component: VendorDashboard },
	{ path: "/admin-panel/verifier-dashboard", component: VerifierDashboard },
	{ path: "/admin-panel/doner-dashboard", component: DonerDashboard },
	
	

	/* Reports */
	{ path: "/admin-panel/beneficiary-report", component: BeneficiaryReport },
	{ path: "/admin-panel/kit-stock-report", component: KitStockReport },
	{ path: "/admin-panel/beneficiary-verify-report", component: BeneficiaryVerifyReport },
	{ path: "/admin-panel/kit-verify-report", component: KitVerifyReport },
	
	
	
		
	
	
	/* common */	
	{ path: "/admin-panel/profile", component: Profile },
	{ path: "/admin-panel/user-dashboard", component: UserDashboard },
	
	
	
	


	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to= "/admin-panel/login"/> },
];

const userRoutes = [];

const publicRoutes = [
	{ path: "/admin-panel/logout", component: Logout },
	{ path: "/admin-panel/login", component: Login },
	{ path: "/admin-panel/forgot-password", component: ForgetPwd },
	{ path: "/admin-panel/register", component: Register },
	{ path: "/admin-panel/auth-lock-screen", component: AuthLockScreen },
	//{ path: "/admin-panel/fms", component: FMS },
];

export { authProtectedRoutes, publicRoutes, userRoutes, isRole };
