import React, { Component } from "react";
import { Table, Row, Col, Card, CardBody, Container, Button, Form, FormGroup, Label, Input, FormText,
Pagination, PaginationItem, PaginationLink, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput } from "reactstrap";
import ReactDOM from 'react-dom';
import User from '../../apis/User';
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom";
import {DateFormat,DateTimeFormat,Loading,SuccessPopup, SwalClose, ErrorPopup,ErrorMessage,Checkbox} from "../../helpers/globalfunctions";
import CommonApi from '../../apis/CommonApi';
import Swal from 'sweetalert2';
import Select from "react-select";
import Compressor from 'compressorjs';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class UserCardGenerate extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "User", link : "#" },
                { title : "Card Generate", link : "#" },
            ],           
            listEmployee:[],
            pageCount: 1,
            currentPage: 1,
            per_page:500,
            search:'',
            isWaiting:false,            
            selectedMulti: '',
            selectedMultiAreaCluster:'',
            selectedMultiArea: '',
            area_name: '',
            values:[],
            area_id:'',
            area_type:'',
            area_type_id:'',
            cardStatus:[],
            checkedItems: new Map(),
            Checked: false,
            city_village:'',
            area_cluster:'',
            area_village:'',
            cardGenerateModalIsOpen:false,
            card_generated:'No',
            check_all:false,
            isCheckAll:false,
            isCheck:[],
            checkboxes: [],
            checkCount:0,
        };

        this.handlePageClick = this.handlePageClick.bind(this);
        this.rowPerPageChange = this.rowPerPageChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.statusChange=this.statusChange.bind(this);        
        this.handleMulti = this.handleMulti.bind(this);
        this.handleMultiAreaCluster=this.handleMultiAreaCluster.bind(this);
        this.areaList= this.areaList.bind(this);        
        this.GetAreaTypeList= this.GetAreaTypeList.bind(this);
        this.areaClusterList=this.areaClusterList.bind(this);
        this.searchRecord=this.searchRecord.bind(this);
        this.handleInputChange=this.handleInputChange.bind(this);
        this.uncheckall=this.uncheckall.bind(this);
        this.checkall=this.checkall.bind(this);
        this.cardGeneratedChange=this.cardGeneratedChange.bind(this);
        this.downloadCard=this.downloadCard.bind(this);
        //this.handleCheckboxChange=this.handleCheckboxChange.bind(this);
    }

    componentDidMount() {
        this._isMounted = true
        this.getUserData()
        
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    //Get Use data
    getUserData() {
        Loading()
        User.employeeList(this.state.currentPage,this.state.per_page,this.state.search).then(response => {
            if (response) {
                SwalClose()
                if(this._isMounted) {
                    this.setState({listEmployee: response.data.data.data});
                    this.setState({pageCount: response.data.data.last_page});
                    this.setState({currentPage: response.data.data.current_page});
                    this.setState({per_page: response.data.data.per_page});

                    const OPTIONS  = response.data.data.data.map(row => row.id);                    
                    this.setState({checkboxes: OPTIONS.reduce(
                      (options, option) => ({
                        ...options,
                        [option]: false
                      }),
                      {}
                    )})
                }
            }
        });
        
    }

    // Get Area type list
    GetAreaTypeList(){
        CommonApi.GetAreaTypeList().then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({area_type_id: [{label: "Select",options:response.data.data}]});
                }                    
            }
        });
    }

    // Get area cluster list 
    areaClusterList(data){
        CommonApi.getAreaClusterList(data).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({area_cluster_id: [{label: "Select",options:response.data.data}]});
                }                    
            }
        });
    }

     // row per page    
    async rowPerPageChange(event) {        
        await this.setState({per_page: event.target.value});
    }

    // page change event
    async handlePageClick(data) {
        const page = data.selected >= 0 ? data.selected + 1 : 0;
        await Promise.resolve(this.setState(() => ({ currentPage: page })));        
        this.getUserData()
    }

    // on change search
    async handleSearch(searchText) {
        await this.setState({ search: searchText.target.value });
        await Promise.resolve(this.setState(() => ({ currentPage: 1 })));
        this.getUserData()
    }

    //on change card genarate dropdown
    async cardGeneratedChange(event) {        
        await this.setState({card_generated: event.target.value});
        await this.setState({listEmployee:[]})
        await this.setState({check_all:false})
        this.selectAllCheckboxes(false);
        //this.getUserData()
    }
    

    /* change active status */
    async statusChange(id){
        Loading()
        this.setState({ isWaiting: true });
        CommonApi.ChangeUserStatus(id).then(response => {
            SwalClose()
            if (response) {                
                this.getUserData();
                this.setState({ isWaiting: false });
            }
        });
    }

    // Get area list
    areaList(data){
        CommonApi.getAreaList(data).then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({area_id: [{label: "Select",options:response.data.data}]});
                }                    
            }
        });
    }

    /* select area type */
    handleMulti = selectedMulti => {
        this.setState({ selectedMulti });
        this.setState({ city_village: selectedMulti.value });
        this.setState({selectedMultiAreaCluster:[]})
        this.areaClusterList(selectedMulti.value);
        this.setState({selectedMultiArea:[]})
        this.areaList(selectedMulti.value);
    };

    /* Select area cluster */
    handleMultiAreaCluster = selectedMultiAreaCluster => {
        this.setState({ selectedMultiAreaCluster });
        this.setState({ area_cluster: selectedMultiAreaCluster.value });
        this.setState({selectedMultiArea:[]})
        this.areaList(selectedMultiAreaCluster.value);
    };

    /* Select area */
    handleMultiArea = selectedMultiArea => {
        this.setState({ selectedMultiArea });
        this.setState({ area_village: selectedMultiArea.value });
    };
    
    /* search button event */
    searchRecord(event){
        this.getUserData()
    }

    /* checkbox change event */
    handleInputChange(event) {        
        var value = event.target.value;        

        const { id, checked } = event.target;
        this.setState([...isCheck, id]);
        console.log('Add');
        //console.log(isCheck)
        if (!checked) {
          this.setState(isCheck.filter(item => item !== id));
          console.log('Single');
        }
    }

    // uncheck all
    async uncheckall(event) {
      let checkboxes = document.getElementsByName('cardStatus');
      for(var i=0, n=checkboxes.length;i<n;i++) {
        checkboxes[i].checked = false;
        var value = checkboxes[i].value;
        this.state.cardStatus.splice(value, 1);
      }
      console.log(this.state.cardStatus);
    }

    // check all
    async checkall(event) {
        const isChecked = event.target.checked;        
        if(isChecked==true){                        
            this.selectAllCheckboxes(true);
        }else{            
            this.selectAllCheckboxes(false);
        }     
      
    }

    /* import beneficiary model */
    openCardGenerateModal = () => this.setState({ cardGenerateModalIsOpen: true });
    closeCardGenerateModal = () => this.setState({ cardGenerateModalIsOpen: false });

    
    /* download card */
    downloadCard(event){
        event.preventDefault();
        const beneficiaryCheck=this.state.checkboxes;        

        var checkCount=0;
        Object.keys(this.state.checkboxes)
          .filter(checkbox => this.state.checkboxes[checkbox])          
          .forEach(checkbox => {
            //console.log(checkbox, "is selected.");                        
            checkCount++
          }
        );

        if(checkCount <= 0) {            
            ErrorMessage("Please select User before Download card");
            return false;
        }

        Loading()
        const data = this.state.checkboxes;

        CommonApi.downloadUserCard(data).then(response => {
            SwalClose()
            
            if (response.data.status==0) {                                
                ErrorMessage(response.data.message)                
            }else{
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
               var fileLink = document.createElement('a');
               fileLink.href = fileURL;
               fileLink.setAttribute('download', 'user-card.pdf');
               //fileLink.download = 'SamplePDF.pdf';
               document.body.appendChild(fileLink);
               fileLink.click();
               
               this.selectAllCheckboxes(false);
            }
        }).catch((error) => {
            SwalClose()
            if (error.response) {
                ErrorPopup(error.response)
            }

        });
    }

    handleCheckboxChange = changeEvent => {        
        const { name } = changeEvent.target;
        const isChecked = event.target.checked;
        console.log(name);
        this.setState(prevState => ({
          checkboxes: {
            ...prevState.checkboxes,
            [name]: !prevState.checkboxes[name]
          }
        }));            
        
    };

    selectAllCheckboxes = isSelected => {
        //console.log('select all');        
        Object.keys(this.state.checkboxes).forEach(checkbox => {
          
          this.setState(prevState => ({
            checkboxes: {
              ...prevState.checkboxes,
              [checkbox]: isSelected
            }
          }));
        });        
    };  

    render() {

        const { selectedMulti } = this.state;
        const { selectedMultiArea } = this.state;
        const { selectedMultiAreaCluster } =this.state;        

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="User Card Generate" breadcrumbItems={this.state.breadcrumbItems} />
                        
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Row>                                            

                                            <Col lg={2} sm={6}>
                                                <Button className="btn-block" color="dark" onClick={this.downloadCard}>Download Card</Button>                                                
                                            </Col>                                            
                                        </Row>
                                        

                                        {/*<Row>
                                            <Col lg={2}>
                                                <Row>
                                                    <Col lg={3}>
                                                        
                                                    </Col>
                                                    <Col lg={6}>
                                                        
                                                    </Col>
                                                </Row>                                                 
                                            </Col>
                                            <Col lg={7}>
                                                
                                            </Col>
                                            <Col lg={3}>
                                                 <Row>
                                                    <Col lg={3}>
                                                        <FormGroup className="mt-2">                                    
                                                            <Label for="search">search</Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg={9}>
                                                        <Form>
                                                          <FormGroup>                                                            
                                                            <Input type="text" onKeyUp={this.handleSearch} name="search" id="search" placeholder="" />
                                                          </FormGroup>
                                                        </Form>
                                                    </Col>
                                                 </Row>                                                
                                            </Col>

                                        </Row>*/}


        
                                        <div className="table-responsive mt-3">
                                            <Table className="mb-0" striped bordered hover>
                                                <thead className="bg-primary text-white">
                                                    <tr>
                                                        <th>
                                                            
                                                            <FormGroup check>                                                                
                                                                <Input type="checkbox" name="check_all" id="check_all" defaultChecked={this.state.check_all} value=""
                                                                onChange={this.checkall}
                                                                className="" 
                                                                style={{width: "20px",height: "20px"}}
                                                                />
                                                                <Label for="check_all" className="ml-1 pt-2">All</Label>                                                                            
                                                            </FormGroup>

                                                        </th>                                                        
                                                        <th>Name</th>
                                                        <th>Photo</th>
                                                        <th>Email</th>
                                                        <th>Mobile No</th>
                                                        <th>Role</th>                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.listEmployee ?(
                                                            this.state.listEmployee.map((option,index)=>{
                                                                return <tr key={index}>
                                                                            <td>
                                                                                <FormGroup check>
                                                                                    <Checkbox
                                                                                      key={option.id}
                                                                                      type="checkbox"
                                                                                      label={option.id}
                                                                                      id={option.id}                                                                                      
                                                                                      isSelected={this.state.checkboxes[option.id]}
                                                                                      onCheckboxChange={this.handleCheckboxChange}
                                                                                    />
                                                                                </FormGroup>
                                                                            </td>                                                                          
                                                                          <td>{option.first_name}</td>
                                                                          <td>{option.attachments?(
                                                                                    option.attachments.map((attValue, attIndex)=>{
                                                                                        return <a key={attIndex} href={(attValue.attachment_name=='beneficiary_photo')?attValue.path:''} target="_blank">{(attValue.attachment_name=='beneficiary_photo')?<img src={attValue.path} width="50px" height="50px" />:''}</a>
                                                                                    })
                                                                                    ):null
                                                                            }</td>                                                                          
                                                                          <td>{option.email}</td>                                                                                        
                                                                          <td>{option.mobile_no}</td>
                                                                          <td>{option.role_name}</td>
                                                                       </tr>
                                                            })
                                                        ): null
                                                    }                                                   
                                                   
                                                    
                                                </tbody>
                                            </Table>
                                        </div>

                                    {/* paginnation */}
                                    <ReactPaginate
                                      previousLabel="&#x276E;"
                                      nextLabel="&#x276F;"
                                      breakLabel={'...'}
                                      breakClassName={'break-me'}
                                      pageCount={this.state.pageCount}
                                      initialPage={this.state.currentPage - 1}
                                      forcePage={this.state.currentPage - 1}
                                      marginPagesDisplayed={2}
                                      pageRangeDisplayed={5}
                                      onPageChange={this.handlePageClick}
                                      containerClassName={'pagination mt-4 float-right'}
                                      activeClassName={'active'}
                                      disableInitialCallback={true}
                                    /> 
        
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default UserCardGenerate;