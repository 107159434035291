import React, { Component } from 'react';
import { Table, Row, Col, Card, CardBody, Container, Button, Form, FormGroup, Label, Input, FormText,
Pagination, PaginationItem, PaginationLink, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput } from "reactstrap";
import { Link } from "react-router-dom";
import { QrReader } from 'react-qr-reader';
import {DateFormat,DateTimeFormat,Loading,SuccessPopup, SwalClose, ErrorPopup, ErrorMessage} from "../../helpers/globalfunctions";
import CommonApi from '../../apis/CommonApi';
import Swal from 'sweetalert2';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class QRscanner extends Component {  
  _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
                { title : "Kit", link : "#" },
                { title : "Scan Card", link : "#" },
            ],                        
            isWaiting:false,
            modalIsOpen: false,
            qr_code:"",
            confirmModalIsOpen:false,
        };
        
        this.handleScan=this.handleScan.bind(this);
        this.handleError=this.handleError.bind(this);
        this.confirmModal=this.confirmModal.bind(this);
        this.handleQRScanKitSubmit=this.handleQRScanKitSubmit.bind(this);
    }

    componentDidMount() {
        this._isMounted = true        
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleScan(data){
	    if (data) {	      
	      console.log(data.text);
	      this.confirmModal(data.text)
	    }
	};
	handleError(err){
	   console.error(err);
	};

  /* Confirm Modal */
    async confirmModal(code){
        await this.setState({confirmModalIsOpen:true});
        await this.setState({qr_code:code});
    }
    /* Submit QR code function */
    handleQRScanKitSubmit(event){
        event.preventDefault();
        Loading()        

        CommonApi.qrCodeSubmit(this.state.qr_code).then(response => {
            SwalClose()
            
            if (response.data.status==1) {
                this.setState({confirmModalIsOpen:false});
                SuccessPopup(response.data.message)
                //this.getKitData();
            }else{
            	ErrorMessage(response.data.message)
            }
        }).catch((error) => {
            SwalClose()
            if (error.response) {
                ErrorPopup(error.response)
            }

        });
    }

    render() {
	  	return (

	  		<React.Fragment>
                <div className="page-content">
                	<Container fluid>

                    <Breadcrumbs title="Scan Card" breadcrumbItems={this.state.breadcrumbItems} />
	      				
	      				<Row>
                            {/* Confirm Modal */ }
                            <Modal isOpen={this.state.confirmModalIsOpen}>
                                <ModalHeader>Confirm Kit Distribution? </ModalHeader>
                                <Form onSubmit={this.handleQRScanKitSubmit}>
                                <ModalBody>
                                    <p>Are you sure you want distribut kit to this beneficiary?</p>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="secondary" onClick={()=>this.setState({confirmModalIsOpen:false})}>Cancel</Button>{' '}
                                    <Button type="submit" color="primary">Confirm Kit Distribution</Button>                           
                                </ModalFooter>
                                </Form>
                            </Modal>
                        </Row>

	      				<Row>
                            <Col lg={12} className="mb-2">
						      <QrReader
						        delay={300}
                                constraints={{
                                    facingMode: 'environment'
                                }}
						        onError={this.handleError}
						        onResult={this.handleScan}						        
						        style={{ width: '50%', height: "50%" }}
						      />
						    </Col>
						</Row>

			    	</Container>
                </div>
            </React.Fragment>
	    
	  	);

	}
}
export default QRscanner;