import React, { Component } from 'react';
import { Container, Row, Col, Table, Card, CardBody, Button, Form, FormGroup, Label, Input, FormText,
Pagination, PaginationItem, PaginationLink } from "reactstrap";
//import MiniWidgets from "./MiniWidgets";
//import RevenueAnalytics from "./RevenueAnalytics";
import CommonApi from '../../apis/CommonApi';


//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
//import Stats from '../../apis/Stats';
let isMounted = false;
class StarterPage extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state={
            breadcrumbItems : [
                { title : "Dashboard", link : "#" },                
            ],
            all_beneficiary:'',
            city_beneficiary:'',
            village_beneficiary:'',
            total_deliverd:'',
            city_deliverd:'',
            village_deliverd:'',
            vendor_stock_report:[],
            total_beneficiary_verify:'',
            beneficiary_not_verify:'',
            total_kit_verify:'', 
            kit_not_verify:'',
            supervisor_stock_report:[],
        }
    }

    componentDidMount(){
        this._isMounted = true;
        this.getDashboardReport()
        
    }

    getDashboardReport() {
        CommonApi.getDashboardReport().then(response => {
            if (response) {
                if(this._isMounted) {
                    this.setState({all_beneficiary: response.data.data.all_beneficiary});
                    this.setState({city_beneficiary: response.data.data.city_beneficiary});
                    this.setState({village_beneficiary: response.data.data.village_beneficiary});
                    this.setState({total_deliverd: response.data.data.total_deliverd});
                    this.setState({city_deliverd: response.data.data.city_deliverd}); 
                    this.setState({village_deliverd: response.data.data.village_deliverd});
                    this.setState({vendor_stock_report: response.data.data.vendor_stock_report});
                    this.setState({total_beneficiary_verify: response.data.data.total_beneficiary_verify});
                    this.setState({beneficiary_not_verify: response.data.data.beneficiary_not_verify}); 
                    this.setState({total_kit_verify: response.data.data.total_kit_verify});
                    this.setState({kit_not_verify: response.data.data.kit_not_verify});
                    this.setState({supervisor_stock_report: response.data.data.supervisor_stock_report});                                        
                }
            }
        });
    }


    render() {        
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Dashboard" breadcrumbItems={this.state.breadcrumbItems} />

                        <Row>
                            <Col lg={6}>
                                <Card>
                                    <CardBody>
                                        <div className="table-responsive mt-3">
                                            <center><h5>Beneficiary</h5></center>
                                            <Table className="mb-0" striped bordered hover>
                                                <thead className="bg-primary text-white">
                                                    <tr>
                                                        <th>Total Beneficiary</th>
                                                        <th>City Beneficiary</th>
                                                        <th>Village Beneficiary</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>                                                                                                                                                                                                                               
                                                      <td>{this.state.all_beneficiary}</td>              
                                                      <td>{this.state.city_beneficiary}</td>
                                                      <td>{this.state.village_beneficiary}</td>                                                                          
                                                   </tr>
                                                    
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={6}>
                                <Card>
                                    <CardBody>
                                        <div className="table-responsive mt-3">
                                            <center><h5>Kit Distribution</h5></center>
                                            <Table className="mb-0" striped bordered hover>
                                                <thead className="bg-primary text-white">
                                                    <tr>
                                                        <th>Total</th>
                                                        <th>City</th>
                                                        <th>Village</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>                                                                                                                                                                                                                               
                                                      <td>{this.state.total_deliverd}</td>              
                                                      <td>{this.state.city_deliverd}</td>
                                                      <td>{this.state.village_deliverd}</td>                                                                          
                                                   </tr>
                                                    
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>


                        
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <center><h5>Kit Stock Report Based On Vendor</h5></center>
                                        <div className="table-responsive">
                                            <Table className="mb-0" striped bordered hover>
                                                <thead className="bg-primary text-white">
                                                    <tr>                                                        
                                                        <th>Vendor Name</th>
                                                        <th>Total Stock</th>                                                        
                                                        <th>Send Stock</th>
                                                        <th>Return Back</th>                                                        
                                                        <th>Available Stock</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                   {
                                                        this.state.vendor_stock_report ?(
                                                            this.state.vendor_stock_report.map((option,index)=>{
                                                                return <tr key={index}>                                                                          
                                                                          <td>{option.vendor_name}</td>
                                                                          <td>{option.total_kit_stock}</td>
                                                                          <td>{option.on_way_stock}</td>
                                                                          <td>{option.return_back}</td>
                                                                          <td>{option.available_stock}</td>
                                                                       </tr>
                                                            })
                                                        ): null
                                                    }
                                                    
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <center><h5>Kit Stock Report Based On Supervisor</h5></center>
                                        <div className="table-responsive">
                                            <Table className="mb-0" striped bordered hover>
                                                <thead className="bg-primary text-white">
                                                    <tr>                                                        
                                                        <th>Supervisor Name</th>
                                                        <th>Total Delivered</th>                                                        
                                                        <th>Today Delivered</th>
                                                        <th>Total Remaining</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                   {
                                                        this.state.supervisor_stock_report ?(
                                                            this.state.supervisor_stock_report.map((option,index)=>{
                                                                return <tr key={index}>                                                                          
                                                                          <td>{option.supervisor_name}</td>
                                                                          <td>{option.total_deliverd}</td>
                                                                          <td>{option.today_deliverd}</td>
                                                                          <td>{option.total_remaining}</td>                                                                          
                                                                       </tr>
                                                            })
                                                        ): null
                                                    }
                                                    
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>                            
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <div className="table-responsive mt-3">
                                            <center><h5>Call Verification</h5></center>
                                            <Table className="mb-0" striped bordered hover>
                                                <thead className="bg-primary text-white">
                                                    <tr>
                                                        <th>Total Beneficiary Verify</th>
                                                        <th>Total Kit Verify</th>                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>                                                                                                                                                                                                                               
                                                      <td>{this.state.total_beneficiary_verify}</td>              
                                                      <td>{this.state.total_kit_verify}</td>                                                                                                                              
                                                   </tr>
                                                    
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>                        
                        </Row> 
                                                                       

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default StarterPage;
